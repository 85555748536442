// source - https://github.com/helenamerk/mic-check/blob/main/src/requestMediaPermissions.tsx

type MediaPermissionsError = {
  type?: MediaPermissionsErrorType;
  name: string;
  message?: string;
};

enum MediaPermissionsErrorType {
  /** (macOS) browser does not have permission to access cam/mic */
  SystemPermissionDenied = 'SystemPermissionDenied',
  /** user denied permission for site to access cam/mic */
  UserPermissionDenied = 'UserPermissionDenied',
  /** (Windows) browser does not have permission to access cam/mic OR camera is in use by another application or browser tab */
  CouldNotStartVideoSource = 'CouldNotStartVideoSource',
  /** all other errors */
  Generic = 'Generic',
}

export const requestMediaPermissions = (constraints?: MediaStreamConstraints) => {
  return new Promise<boolean | MediaPermissionsError>((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia(constraints ?? {audio: true, video: true})
      .then((stream: MediaStream) => {
        stream.getTracks().forEach((t) => {
          t.stop();
        });
        resolve(true);
      })
      .catch(async (err: any) => {
        const errName = err.name;
        switch (errName) {
          case 'NotAllowedError':
            resolve(genErrorObject(err, handleNotAllowedError(err)));
            break;
          case 'NotFoundError':
            resolve(genErrorObject(err, MediaPermissionsErrorType.SystemPermissionDenied));
            break;
          case 'NotReadableError':
            const {UAParser} = await import('ua-parser-js');
            const uaParser = new UAParser();
            const browserName = uaParser.getBrowser().name?.toLowerCase();
            resolve(genErrorObject(err, handleNotReadableError(browserName)));
            break;
          default:
            resolve(genErrorObject(err, MediaPermissionsErrorType.Generic));
            break;
        }
      });
  });
};

export const requestAudioPermissions = () => requestMediaPermissions({audio: true, video: false});
export const requestVideoPermissions = () => requestMediaPermissions({audio: false, video: true});

const handleNotAllowedError = (err: MediaPermissionsError) => {
  const errMessage = err.message;
  if (errMessage?.includes('system')) {
    return MediaPermissionsErrorType.SystemPermissionDenied;
  }
  return MediaPermissionsErrorType.UserPermissionDenied;
};
const genErrorObject = (
  err: MediaPermissionsError,
  type: MediaPermissionsErrorType
): MediaPermissionsError => {
  return {
    type,
    name: err.name,
    message: err.message,
  };
};
const handleNotReadableError = (browserName: string | undefined): MediaPermissionsErrorType => {
  return browserName && ['firefox'].includes(browserName)
    ? MediaPermissionsErrorType.SystemPermissionDenied
    : MediaPermissionsErrorType.CouldNotStartVideoSource;
};
