import type firebase from 'firebase';
import {AnalyticsView} from '../types/session';
import {DbOrderModel} from './order';
import {DbSessionLiveDataModel, DbSessionMessageModel, DbSessionSeatModel} from './session';
import {DbUserModel} from './user';
type Timestamp = firebase.firestore.Timestamp;

export const currentVersion = '1.0.1';

// todo create item view event
// todo create purchase cart event
// todo create host id change event
// todo create host name change event
// todo create host image change event
// todo session time event
// todo product added to session event
// todo product removed from session event
// todo discount changed event (from settings)
// todo product inventory changed event

export enum EventName {
  SessionStateChange = 'SessionStateChange',
  AddToCartEvent = 'AddToCartEvent',
  RemoveFromCartEvent = 'RemoveFromCartEvent',
  UserJointEvent = 'UserJointEvent',
  UserLeftEvent = 'UserLeftEvent',
  UserLikeEvent = 'UserLikeEvent',
  UserUnlikeEvent = 'UserUnlikeEvent',

  // TODO: Old share events - think when they might be removed
  UserShareEvent = 'UserShareEvent',
  HostShareEvent = 'HostShareEvent',
  StoreAdminShareEvent = 'StoreAdminShareEvent',

  ShareSessionEvent = 'ShareSessionEvent',
  ShareProductEvent = 'ShareProductEvent',
  UseShareSessionEvent = 'UseShareSessionEvent',
  UseShareProductEvent = 'UseShareProductEvent',
  ItemSoldEvent = 'ItemSoldEvent',
  OpenCartEvent = 'OpenCartEvent',
  CloseCartEvent = 'CloseCartEvent',
  UserMessageEvent = 'UserMessageEvent',
  ChangeFeaturedItemEvent = 'ChangeFeaturedItemEvent',
  ClickedToCheckoutEvent = 'ClickedToCheckoutEvent',
  PollViewed = 'PollViewed',
  PollVoted = 'PollVoted',
  PollItemViewed = 'PollItemViewed',
  PollActivated = 'PollActivated',
  PollDeactivated = 'PollDeactivated',
  PollDeleted = 'PollDeleted',
}

export type PossibleSessionEventsName = keyof typeof EventName | EventName;

export type ShareEvents =
  | EventName.ShareSessionEvent
  | EventName.ShareProductEvent
  | EventName.UseShareSessionEvent
  | EventName.UseShareProductEvent;

export type PollEvents =
  | EventName.PollViewed
  | EventName.PollVoted
  | EventName.PollItemViewed
  | EventName.PollActivated
  | EventName.PollDeactivated
  | EventName.PollDeleted;

export class SessionEvent<T extends SessionEventData = SessionEventData> {
  readonly name: PossibleSessionEventsName;
  readonly timeStamp: Timestamp;
  readonly sessionId: string;
  readonly data: T;
  readonly version: string = currentVersion;
  readonly analyticsViewState: AnalyticsView;

  private constructor(sessionId: string, data: T, timestamp: Timestamp, state: AnalyticsView) {
    this.data = data;
    this.name = data.name;
    this.sessionId = data.sessionId = sessionId;
    this.timeStamp = this.data.timeStamp = timestamp;
    this.analyticsViewState = this.data.analyticsViewState = state;

    Object.entries(this).forEach(([k, v]) => {
      const me = this as any;
      if (v === undefined) me[k] = null;
    });
    Object.entries(data).forEach(([k, v]) => {
      const myData = data as any;
      if (v === undefined) myData[k] = null;
    });
  }

  static createEventFromEventData<T extends SessionEventData>(
    sessionId: string,
    data: T,
    timestamp: Timestamp,
    state: AnalyticsView
  ) {
    const event = new SessionEvent(sessionId, data, timestamp, state);
    const convertedToPlainObject = {...event};
    return convertedToPlainObject;
  }
}

export interface SessionEventData {
  name: EventName;
  analyticsViewState?: AnalyticsView;
  sessionId?: string;
  timeStamp?: Timestamp;
}

export class SessionStateChange implements SessionEventData {
  name = EventName.SessionStateChange;
  state: DbSessionLiveDataModel['sessionState'];
}

export class AddToCartEvent implements SessionEventData {
  name = EventName.AddToCartEvent;
  userId: string | undefined;
  productId: string;
  variantId: string;
  quantity: number;
}

export class RemoveFromCartEvent implements SessionEventData {
  name = EventName.RemoveFromCartEvent;
  userId: string | undefined;
  productId: string;
  variantId: string;
  quantity: number;
}

export class UserJointEvent implements SessionEventData {
  name = EventName.UserJointEvent;
  user: DbUserModel;
  ipAddress: string;
  userId: string | undefined;
  seatCreateTime: DbSessionSeatModel['createDate'];
  seatLastHeartBeat: DbSessionSeatModel['lastHeartbeat'];
  seatId: DbSessionSeatModel['id'];
  seatNumber: DbSessionSeatModel['seatNumber'];
  seatIsReleased: DbSessionSeatModel['isReleased'];
}

export class UserLeftEvent implements SessionEventData {
  name = EventName.UserJointEvent;
  user: DbUserModel;
  ipAddress: string;
  userId: string | undefined;
  seatCreateTime: DbSessionSeatModel['createDate'];
  seatLastHeartBeat: DbSessionSeatModel['lastHeartbeat'];
  seatId: DbSessionSeatModel['id'];
  seatNumber: DbSessionSeatModel['seatNumber'];
  seatIsReleased: DbSessionSeatModel['isReleased'];
  secondsOfView: DbSessionSeatModel['secondsOfView'];
}

export class UserLikeEvent implements SessionEventData {
  name = EventName.UserLikeEvent;
  userId: string | undefined;
}

export class UserUnlikeEvent implements SessionEventData {
  name = EventName.UserUnlikeEvent;
  userId: string | undefined;
}

export class UserMessageEvent extends DbSessionMessageModel {
  name = EventName.UserMessageEvent;
}

export class UserShareEvent implements SessionEventData {
  name = EventName.UserShareEvent;
  userId: string | undefined;
}

export class HostShareEvent implements SessionEventData {
  name = EventName.HostShareEvent;
  userId: string | undefined;
}

export class ShareSessionEvent implements SessionEventData {
  name = EventName.ShareSessionEvent;
  userId: string | undefined;
  linkId: string;
}

export class ShareProductEvent implements SessionEventData {
  name = EventName.ShareProductEvent;
  userId: string | undefined;
  linkId: string;
}

export class UseShareSessionEvent implements SessionEventData {
  name = EventName.UseShareSessionEvent;
  userId: string | undefined;
  linkId: string;
}

export class UseShareProductEvent implements SessionEventData {
  name = EventName.UseShareProductEvent;
  userId: string | undefined;
  linkId: string;
}

export class StoreAdminShareEvent implements SessionEventData {
  name = EventName.StoreAdminShareEvent;
  userId: string | undefined;
}
export class ItemSoldEvent implements SessionEventData {
  name = EventName.ItemSoldEvent;
  userId: string;
  productId: string;
  variantId: string;
  price: number;
  quantity: number;
}

export class OpenCartEvent implements SessionEventData {
  name = EventName.OpenCartEvent;
  userId: string;
  cartId: string;
}

export class CloseCartEvent implements SessionEventData {
  name = EventName.CloseCartEvent;
  userId: string | undefined;
  cartId: string | undefined;
  cartTotal: number | undefined;
}

export class ChangeFeaturedItemEvent implements SessionEventData {
  name = EventName.ChangeFeaturedItemEvent;
  productId: string;
  variantId: string;
}

export class ClickedToCheckoutEvent implements SessionEventData {
  name = EventName.ClickedToCheckoutEvent;
  userId: string;
  purposeId: string; // cartId
  orderModel?: DbOrderModel;
  orderId?: string;
  orderTotal?: number;
  paymentId: string;
  resData: any;
}

export interface LanguageCount {
  language: string;
  count: number;
}

export interface CityCount {
  city: string;
  count: number;
}
