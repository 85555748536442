import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {LoggerService} from './services/logger.service';
type Message = string;
type AlternativeLogMessage = string;
const knownGlobalErrorsThatCantBeHandled: [Message, AlternativeLogMessage][] = [
  ['auth/popup-closed-by-user', 'user closed popup'],
];

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private toaster?: ToastrService;
  private zone?: NgZone;
  // eslint-disable-next-line no-useless-constructor
  constructor(private injector: Injector) {}

  handleError(error?: unknown) {
    if (!error) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access

    this.toaster = this.injector.get(ToastrService);
    this.zone = this.injector.get(NgZone);
    // Check if it's an error from an HTTP response
    try {
      const message: unknown = getMessage(error);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!environment.production && this.toaster && this.zone && typeof message === 'string') {
        const alternativeMessage = knownGlobalErrorsThatCantBeHandled.find((knownError) =>
          message.includes(knownError[0])
        );
        if (alternativeMessage) {
          LoggerService.debug(alternativeMessage[1]);
          return;
        }
        this.zone.run(() => this.toaster?.error('unhandled error: ' + message));
        LoggerService.info('message', message);
      }
    } catch (error) {
      LoggerService.error('Error displaying handler', error);
    }
    LoggerService.error('Error from global error handler', error);
  }
}

/**
 *
 * @param error
 */
function getMessage(error: any): string {
  if (!error) return '';
  if (error instanceof Error) return error.message;
  if (typeof error in ['string', 'number', 'boolean', 'undefined', 'bigint', 'symbol']) {
    return JSON.stringify(error);
  }

  if (typeof error === 'object') {
    try {
      return JSON.stringify(error);
    } catch (error) {}
    for (const key in error) {
      try {
        return getMessage(error[key]);
      } catch (error) {}
    }
  }
  throw error;
}
