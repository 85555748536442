import type {RequireAtLeastOne} from './type-helpers';

export type ObjectPathArray<T> = T extends string | number
  ? []
  : {
      [K in Extract<keyof T, string | number>]: T[K] extends Array<any>
        ? [`${K}[]`, ...ObjectPathArray<T[K][number]>]
        : [K, ...ObjectPathArray<T[K]>];
    }[Extract<keyof T, string | number>];

type PathMaker<
  FullPath extends string | number,
  PartialPath extends string | number,
  ToReturnFullPaths extends boolean = false
> = ToReturnFullPaths extends true ? FullPath : PartialPath | FullPath;

type Join<T extends (string | number)[], ToReturnFullPaths extends boolean = false> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string | number
    ? PathMaker<
        `${F}${'.'}${Join<Extract<R, (string | number)[]>, ToReturnFullPaths>}`,
        F,
        ToReturnFullPaths
      >
    : never
  : string | number;

// test and examples
// let a: DottedStringPaths<{ a: { b: { c: { d: string }[] } } }> = 'a.b.c[12]';
// a = 'a.b.c[12].d';
// let objectPathArray: ObjectPathArray<{a: {b: {c: any}}}> = ['a', 'b', 'c'];

export type DottedStringFullPaths<T> = T extends Record<string, any>
  ? Join<ObjectPathArray<T>, true>
  : never;
export type DottedStringPaths<T> = T extends Record<string, any>
  ? Join<ObjectPathArray<T>, false>
  : never;

export type DottedStringPathsAtKey<T, Key extends keyof T> = DottedStringPaths<Pick<T, Key>>;

export type MappedKeys<T extends Record<string, any>> = {
  [K in `${DottedStringPaths<T>}`]?: unknown;
};
// build object for each key, with all the possible keys paths for that key
export type RequiredKeyPath<
  T extends Record<string, any>,
  Keys extends keyof T
> = RequireAtLeastOne<MappedKeys<Pick<T, Keys>>, keyof MappedKeys<Pick<T, Keys>>>;

// test and examples
// let a: RequiredKeyPath<{a: {b: {c: any}}[]; b: {d: any}}, 'a'> = {'a[13].b.c': 1};

/**
 * convert an object to an array of paths with the value at leaf
 * @param obj an object to convert to an array of paths with the value at leaf
 * @param path the path of the current level. internal use only
 * @returns an array of paths with the value at leaf
 *
 * @example
 * ``` typescript
 * const obj = {
 *  a: 1,
 *  b: {
 *    c: 2,
 *    d: {
 *      e: 3,
 *      f: 4
 *      }
 *    }
 * }
 *
 * ObjectToPathArrayWithValue(obj)
 * // returns
 * [ ['a', 1], ['b.c', 2], ['b.d.e', 3], ['b.d.f', 4] ]
 * ```
 */
export function objectToPathArrayWithValue<T extends Record<string | number, any> = {}>(
  obj: T,
  path: DottedStringPaths<T> = '' as DottedStringPaths<T>
): [DottedStringFullPaths<T>, any][] {
  return (
    Object.entries(obj).map(([key, value]) => {
      if (typeof value === 'object') {
        return objectToPathArrayWithValue(value, path ? `${path}.${key}` : key).flat();
      } else {
        return [(path ? `${path}.${key}` : key) as DottedStringFullPaths<T>, value];
      }
    }) as [DottedStringFullPaths<T>, any]
  )
    .flat()
    .reduce((acc, curr, i) => {
      if (i % 2 === 0) {
        acc.push([curr]);
      } else {
        acc.at(-1)![1] = curr;
      }
      return acc;
    }, [] as [DottedStringFullPaths<T>, any][]);
}

export function setAtLocation<T>(obj: T, path: DottedStringPaths<T>, value: any) {
  if (!obj) return;
  if (!path) return;
  const pathArray = String(path)
    .split('.')
    .flatMap((subPath) => {
      if (subPath.match(/\w\[\]/)) {
        const paths = subPath.split(/(\[\])/).filter((p) => !!p);
        return paths;
      }
      return subPath;
    });

  do {
    let [key] = (pathArray.shift()?.match(/\w+|\[\]/) ?? []) as (undefined | keyof T)[];
    if (!key) return;

    if (key === '[]') {
      (obj as any[]).push(value);
      return;
    }

    if (pathArray.length === 0) {
      obj[key] = value;
      return;
    }

    if (obj[key] === undefined) {
      const nextKey = pathArray[0];
      const isArray = nextKey.match(/\[\]/);
      obj[key] = isArray ? [] : ({} as any);
    }

    obj = obj[key] as any;
  } while (pathArray.length > 0);
}

export function getFirstValueOfObject<T extends {[key: string]: any}>(obj: T) {
  return getFirstKeyAndValueOfObject<T>(obj)[1];
}

export function getFirstKeyOfObject<T extends {[key: string]: any}>(obj: T) {
  return getFirstKeyAndValueOfObject<T>(obj)[0];
}

export function getFirstKeyAndValueOfObject<T extends {[key: string]: any}>(obj: T) {
  return (Object.entries(obj).at(0) ?? '') as [keyof T, T[keyof T]];
}

export function merge<T>(...args: T[]): T {
  let dst: Record<string, any> = {};
  while (args.length > 0) {
    let src: Record<string, any> | any = args.splice(0, 1)[0];
    if (typeof src !== 'object' || src === null) continue;

    for (let p in src) {
      if (typeof src[p] === 'object' && src[p] !== null) {
        const srcObj = src[p];
        if (Array.isArray(srcObj)) {
          src[p] = srcObj.concat(dst[p] || []);
        } else if (srcObj instanceof Map) {
          src[p] = [...srcObj.values()].concat(dst[p] || []);
        } else if (srcObj instanceof Set) {
          src[p] = [...srcObj.values()].concat(dst[p] || []);
        } else {
          src[p] = merge({}, dst[p] || {}, srcObj);
        }
      }

      dst[p] = src[p];
    }
  }
  return dst as T;
}

export function getValueAtPath<T extends Record<string | number, any>>(
  obj: T,
  path: DottedStringPaths<T>,
  defaultValue?: any
) {
  return String(path)
    .split('.')
    .reduce((acc, curr) => acc?.[curr] ?? defaultValue, obj);
}
