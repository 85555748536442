import {environment} from 'src/environments/environment';
import {Client} from '../../../../shared/utilities/type-helpers';
import {IcoMoonName} from '../components/icon/icomoonName';
import {SessionDataDTO} from '../../../../shared/dto-models/session-data';

interface UserDetails {
  email: string | null | undefined;
  displayName: string | null | undefined;
  phone: string | null | undefined;
}

export const environmentsWithStricterUserValidation: Client['clientType'][] = ['terrific'];

export interface MissingData {
  email: boolean;
  phone: boolean;
  displayName: boolean;
  icon?: IcoMoonName;
  title?: string;
  subtitle?: string;
}

/**
 *
 * @param user
 */
export function isLoggedInForSessionAction(
  user: UserDetails,
  sessionData?: SessionDataDTO | null
): MissingData {
  if (sessionData?.actionsRequireLogin) {
    return {email: !user.email, displayName: !user.displayName, phone: !user.phone};
  }
  return {email: false, displayName: false, phone: false};
}

/**
 *
 * @param user
 */
export function isLoggedInWithUserDetailsRequiredToSendAChatMessage(
  user: UserDetails,
  sessionData: SessionDataDTO
): MissingData {
  if (sessionData.actionsRequireLogin) {
    return {email: !user.email, displayName: !user.displayName, phone: !user.phone};
  }
  return {email: false, displayName: !user.displayName, phone: false};
}
