import {environmentVars} from './internalTest';
import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 'claro', homePageFeedType: 'sessions'};

export const environment = {
  ...environmentVars,
  firebaseConfig: {
    apiKey: 'AIzaSyCsjR5bmDGAlXqB-IsS38bh7wHTGFbU4A4',
    authDomain: 'staging.claro.live',
    databaseURL: 'https://claro-staging-233e2-default-rtdb.firebaseio.com',
    projectId: 'claro-staging-233e2',
    storageBucket: 'claro-staging-233e2.appspot.com',
    messagingSenderId: '895840884373',
    appId: '1:895840884373:web:1ad7c8e2ef2a1b6369ed9c',
    measurementId: 'G-T845XEL3P1',
  },
  traceOrigin: {
    name: 'claro-staging-233e2',
    domain: 'claro-staging-233e2',
  },
  client,
};
