import {inject, Injectable} from '@angular/core';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore, QuerySnapshot} from '@angular/fire/compat/firestore';
import * as Sentry from '@sentry/angular-ivy';

import {combineLatest, firstValueFrom, from, Observable, of} from 'rxjs';
import {catchError, filter, first, map, retry, switchMap, tap} from 'rxjs/operators';
import {
  ConnectedUserModel,
  UserPaymentMethodModel,
  UserProfileModel,
  UserSessionsModel,
} from '../interfaces/users-models';
import firebase from 'firebase/compat/app';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import type {DbAddressModel} from '../../../../shared/db-models/address';
import type {DbUserModel, DbUserSessionsModel} from '../../../../shared/db-models/user';
import {StorageService} from './storage.service';
import {FileHelpers} from '../helpers/file-helpers';
import {OrderModel} from '../interfaces/orders-models';
import type {PaymentSuppliers} from '../../../../shared/db-models/payments';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthModalState, LoginModalComponent} from '../modals/login-modal/login-modal.component';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app.service';
import type {PaymentDataStripe} from '../../../../shared/dto-models/payments';
import {LanguageService} from '../language.service';
import {
  isLoggedInForSessionAction,
  isLoggedInWithUserDetailsRequiredToSendAChatMessage,
  MissingData,
} from './users.utils';
import {NavigateService} from './navigate.service';
import {AnalyticsService} from './analytics.service';
import {LoggerService} from './logger.service';
import {StateHolderService} from './state-holder.service';
import {Timestamp} from '@angular/fire/firestore';
import UserCredential = firebase.auth.UserCredential;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import AuthCredential = firebase.auth.AuthCredential;
import ConfirmationResult = firebase.auth.ConfirmationResult;
import {CacheAbleShare} from '../helpers/cache.decoretor';
import {SessionDataDTO} from '../../../../shared/dto-models/session-data';

export interface PhoneValidationResult {
  confirm(verificationCode: string): Promise<AuthCredential>;

  verificationId: string;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private modalService = inject(NgbModal);
  private afAuth = inject(AngularFireAuth);
  private firestore = inject(AngularFirestore);
  private fns = inject(AngularFireFunctions);
  private storage = inject(StorageService);
  private router = inject(Router);
  private analytics = inject(AnalyticsService);
  private http = inject(HttpClient);
  private navigateService = inject(NavigateService);
  protected appService = inject(AppService);
  protected languageService = inject(LanguageService);
  protected stateService = inject(StateHolderService);
  private readonly LOCAL_STORAGE_SIGN_IN_FROM_REDIRECT_KEY_NAME = 'signInFromRedirect';
  public connectedUser = this.stateService.connectedUser$;
  private userFromRedirect: ConnectedUserModel | null;

  public afUser$ = this.afAuth.user;

  /**
   *
   */
  constructor() {
    this.afAuth.user
      .pipe(
        filter((user) => {
          //todo - invert if
          if (!user) {
            from(this.afAuth.signInAnonymously()).subscribe({
              next: (user) => {
                this.firestore
                  .doc<DbUserModel>(`users/${user.user!.uid}`)
                  .get()
                  .subscribe((userDoc) => {
                    const oneDay = 24 * 60 * 60 * 1000;
                    if (!userDoc.data()?.lastGeoUpdate) {
                      this.updateGeoData();
                      return;
                    }
                    if (
                      !userDoc.data()?.nationality ||
                      !userDoc.data()?.city ||
                      !userDoc.data()?.language ||
                      Timestamp.now().toMillis() - userDoc.data()!.lastGeoUpdate!.toMillis() >
                        oneDay
                    ) {
                      this.updateGeoData();
                    }
                  });
              },
              error: (e) => LoggerService.error(e),
            });
          }

          return !!user;
        }),
        switchMap((_firebaseUser) => {
          const firebaseUser = _firebaseUser!;
          // Check if admin
          return combineLatest({
            idTokenResult: from(firebaseUser.getIdTokenResult(false)).pipe(
              switchMap((idTokenResult) => {
                const ageToRefresh = 10 * 60 * 1000; // 10 minutes
                const tokenAgeMillis = Date.now() - idTokenResult.claims['iat'] * 1000;
                const needRefresh = tokenAgeMillis > ageToRefresh;
                let result: Observable<firebase.auth.IdTokenResult>;
                if (needRefresh) {
                  result = from(firebaseUser.getIdTokenResult(true));
                } else {
                  result = of(idTokenResult);
                }
                return result;
              })
            ),
            userDoc: this.firestore
              .doc<DbUserModel>(`users/${firebaseUser.uid}`)
              .valueChanges()
              .pipe(retry({count: 3, delay: 300, resetOnSuccess: true})),
          }).pipe(
            map(({idTokenResult, userDoc}) => {
              LoggerService.debug('firebaseUser', firebaseUser);
              LoggerService.debug('userDoc', userDoc);
              const phoneNumber = !!userDoc?.phone ? userDoc.phone : firebaseUser.phoneNumber ?? '';
              const user = {
                uid: firebaseUser.uid,
                displayName: userDoc?.displayName ?? firebaseUser.displayName ?? '',
                email: userDoc?.email ?? firebaseUser.email ?? '',
                phone: phoneNumber,
                photoURL: firebaseUser.photoURL ?? 'assets/images/avatar-placeholder.svg',
                isAdmin: !!idTokenResult.claims?.admin,
                stores: idTokenResult.claims?.stores,
                isFinishedRegistration: this.isFinishedRegistration({
                  email: !(userDoc?.email ?? firebaseUser.email),
                  phone: !phoneNumber,
                }),
                isAnonymous: firebaseUser.isAnonymous,
              } as ConnectedUserModel;

              return user;
            })
          );
        })
      )
      .pipe(
        tap((user) => {
          this.connectedUser.next(user);
          try {
            Sentry.setUser({
              email: user.email,
              id: user.uid,
              username: user.displayName,
              segment: Object.entries(user.stores ?? {})
                .map((obj) => JSON.stringify(obj))
                .join(', '),
            });
          } catch (error) {
            LoggerService.error(error);
          }
          try {
            const signInFromRedirect = JSON.parse(
              localStorage.getItem(this.LOCAL_STORAGE_SIGN_IN_FROM_REDIRECT_KEY_NAME)!
            );
            LoggerService.debug('connected user', user);
            // User is only valid once phone number is set
            if (signInFromRedirect && user.email) {
              this.userFromRedirect = user;
              this.handleIfFromLoginRedirect();
            }
          } catch (error) {}
        })
      )
      .subscribe({error: (e) => LoggerService.error(e)});
  }

  private updateGeoData() {
    const userLanguage = navigator.language;
    this.getGeoLocationData()
      .pipe(
        map(() => {
          this.updateUserGeoData(userLanguage).subscribe();
        })
      )
      .subscribe({
        error: (e) => LoggerService.log(e),
      });
  }

  public getIsSuperAdmin() {
    return this.connectedUser.pipe(
      // ask for user, this will trigger the account service to fetch the user from the server
      switchMap(() => this.afUser$), // get the user from the auth service
      switchMap(async (afUser) => afUser?.getIdTokenResult()), // get the token from the user
      map((token) => !!token?.claims.admin)
    );
  }

  /**
   * return active user from local
   */
  public get connectedUserSync(): ConnectedUserModel {
    return (
      this.connectedUser.value ?? {
        uid: '',
        email: '',
        photoURL: '',
        displayName: '',
        phone: '',
        isAdmin: false,
        isAnonymous: true,
        isFinishedRegistration: false,
        stores: {},
      }
    );
  }

  public getUserFromRedirectSync() {
    return this.userFromRedirect;
  }

  public removeUserFromRedirect() {
    this.userFromRedirect = null;
  }

  private async getMissingData(missingData: MissingData) {
    try {
      if (!this.isFinishedRegistration(missingData)) {
        return await firstValueFrom(this.openRegisterModal());
      }

      if (this.missingData(missingData)) {
        return await firstValueFrom(this.openInputMissingDataModal(missingData));
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  private isFinishedRegistration(missingData: {email: boolean; phone: boolean}) {
    const isFinishedRegistration = !missingData.phone && !missingData.email;
    return isFinishedRegistration;
  }

  private missingData(missingData: MissingData) {
    return Object.values(missingData).some((x) => !!x);
  }

  public async verifyLoggedInForSessionAction(
    session: SessionDataDTO | null,
    user?: ConnectedUserModel
  ) {
    if (!user) user = this.connectedUserSync;
    await this.getMissingData(isLoggedInForSessionAction(user, session));
    user = this.connectedUserSync;
    if (this.missingData(isLoggedInForSessionAction(user, session))) {
      throw new Error('user not logged in');
    }
  }

  public async verifyLoggedInForChatMessage(session: SessionDataDTO, user?: ConnectedUserModel) {
    if (!user) user = this.connectedUserSync;
    await this.getMissingData(isLoggedInWithUserDetailsRequiredToSendAChatMessage(user, session));
    user = this.connectedUserSync;
    if (this.missingData(isLoggedInWithUserDetailsRequiredToSendAChatMessage(user, session))) {
      throw new Error('user not logged in');
    }
  }

  public openInputMissingDataModal(missingData: MissingData) {
    const modal = this.modalService.open(LoginModalComponent, {
      windowClass: 'login-modal',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    (modal.componentInstance as LoginModalComponent).startState = AuthModalState.AskForDetails;
    (modal.componentInstance as LoginModalComponent).showFields = missingData;
    return modal.closed;
  }

  public openLoginModal(): NgbModalRef {
    const modal = this.modalService.open(LoginModalComponent, {
      windowClass: 'login-modal',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
    (modal.componentInstance as LoginModalComponent).startState = AuthModalState.Login;

    return modal;
  }

  public openLoginModalFromRedirect(user: ConnectedUserModel) {
    this.afUser$.pipe(first()).subscribe((firebaseUser) => {
      const modal = this.modalService.open(LoginModalComponent, {
        windowClass: 'login-modal',
        centered: true,
        keyboard: false,
        backdrop: 'static',
      });
      (modal.componentInstance as LoginModalComponent).startState = AuthModalState.PhoneNumber;
      (modal.componentInstance as LoginModalComponent).openedFromRedirect = true;
      (modal.componentInstance as LoginModalComponent).user = firebaseUser;

      modal.result.then(
        () => {
          this.removeUserFromRedirect();
        },
        () => {
          this.removeUserFromRedirect();
        }
      );
    });
  }

  /**
   * Checks and handle if page load from login redirect
   */
  private handleIfFromLoginRedirect() {
    try {
      const isSignInFromRedirect = JSON.parse(
        localStorage.getItem(this.LOCAL_STORAGE_SIGN_IN_FROM_REDIRECT_KEY_NAME) ?? '{}'
      );
      const userFromRedirect = this.getUserFromRedirectSync();
      if (
        userFromRedirect &&
        isSignInFromRedirect &&
        !this.isFinishedRegistration({
          email: !userFromRedirect.email,
          phone: !userFromRedirect.phone,
        })
      ) {
        this.openLoginModalFromRedirect(userFromRedirect);
      }

      localStorage.removeItem(this.LOCAL_STORAGE_SIGN_IN_FROM_REDIRECT_KEY_NAME);
    } catch (error) {}
  }

  public openRegisterModal() {
    const modal = this.modalService.open(LoginModalComponent, {
      windowClass: 'login-modal',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
    (modal.componentInstance as LoginModalComponent).startState = AuthModalState.Register;
    this.analytics.logEvent('sign_up', {
      path: this.router.url,
      method: 'sign_up_clicked',
    });
    return modal.closed;
  }

  public openResetPasswordModal(resetCode: string) {
    this.analytics.logEvent('select_content', {
      path: this.router.url,
      content_type: 'open_reset_password_modal',
    });
    const modal = this.modalService.open(LoginModalComponent, {
      windowClass: 'login-modal',
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });

    (modal.componentInstance as LoginModalComponent).startState = AuthModalState.ResetPassword;
    (modal.componentInstance as LoginModalComponent).resetCode = resetCode;
  }

  public async reloadUser() {
    await this.afAuth.currentUser.then((res) => res?.reload());
  }

  /**
   * Sign up process for Google provider
   */
  public async googleSignUp(): Promise<UserCredential> {
    return await this.signInWithProvider('Google');
  }

  /**
   * Sign up process for Facebook provider
   */
  public async facebookSignUp(): Promise<UserCredential> {
    return await this.signInWithProvider('Facebook');
  }

  /**
   * Start sign up with a specific provider and returns user credential
   *
   * @param prov
   */
  public async signInWithProvider(prov: string): Promise<UserCredential> {
    let provider;
    if (prov === 'Google' || prov.toLowerCase() === 'google.com') {
      provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({prompt: 'select_account'});
    } else {
      provider = new firebase.auth.FacebookAuthProvider();
    }

    // https://stackoverflow.com/a/3540295/7453391
    // let isWebView = false;

    // device detection for fixing bug with login inside webview and other mobile web brosers
    // if (
    //   /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
    //     navigator.userAgent
    //   ) ||
    //   /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
    //     navigator.userAgent.substr(0, 4)
    //   )
    // ) {
    //   isWebView = true;
    // }

    // if (isWebView) {
    // return await this.signInWithRedirect(provider);
    // }

    return await this.afAuth.signInWithPopup(provider);
  }

  /**
   * Links an account with existing credential.
   *
   * @param credential
   */
  public async linkWithExistingCredential(credential: AuthCredential): Promise<any> {
    return await firebase.auth().currentUser?.linkWithCredential(credential);
  }

  /**
   * Unlink an account, mainly for testing purposes.
   *
   * @param provider
   */
  public async unlinkAccount(provider: string): Promise<any> {
    return await firebase.auth().currentUser?.unlink(provider);
  }

  /**
   * Disables captcha for testing purposes.
   * Will work only for fictional phone numbers.
   * https://firebase.google.com/docs/auth/web/phone-auth#integration-testing
   */

  public _disableCaptchaForTesting() {
    firebase.auth().settings.appVerificationDisabledForTesting = true;
  }

  public async fetchSignInMethodForEmail(email: string): Promise<any> {
    return this.afAuth.fetchSignInMethodsForEmail(email);
  }

  // private async signInWithRedirect(provider: any) {
  //   localStorage.setItem(this.LOCAL_STORAGE_SIGN_IN_FROM_REDIRECT_KEY_NAME, JSON.stringify(true));
  //   return await this.afAuth.signInWithRedirect(provider);
  // }

  public getRedirectResult(): Promise<UserCredential> {
    return this.afAuth.getRedirectResult();
  }

  public async signInWithEmail(email: string, password: string): Promise<UserCredential> {
    return await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  public async verifyPhoneNumber(phone: string): Promise<PhoneValidationResult> {
    await this.afAuth.useDeviceLanguage();
    const recaptchaVerifier = this.createRecaptchaVerifier();
    return new firebase.auth.PhoneAuthProvider()
      .verifyPhoneNumber({phoneNumber: phone}, recaptchaVerifier)
      .then(function (verificationId) {
        return {
          verificationId: verificationId,
          confirm: (verificationCode) =>
            Promise.resolve(
              firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
            ),
        };
      });
  }

  public async signInWithPhone(phone: string): Promise<ConfirmationResult> {
    await this.afAuth.useDeviceLanguage();
    const recaptchaVerifier = this.createRecaptchaVerifier();
    return this.afAuth.signInWithPhoneNumber(phone, recaptchaVerifier);
  }

  private createRecaptchaVerifier(numberOfExpired = 0) {
    return new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      // eslint-disable-next-line quote-props
      callback: () => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
      'expired-callback': () => {
        if (++numberOfExpired > 3) return;
        this.createRecaptchaVerifier(numberOfExpired);
      },
    });
  }

  /**
   * Check if the email already register in db
   *
   * @param email
   */
  public async isEmailRegistered(email: string): Promise<string[]> {
    return await this.afAuth.fetchSignInMethodsForEmail(email);
  }

  /**
   * Signout connected user
   *
   * @param activeStoreUrl
   */
  public async signOut(activeStoreUrl?: string) {
    await this.afAuth.signOut();
    if (activeStoreUrl) await this.navigateService.goToStoreUrlWithOptionalParams(activeStoreUrl);
    this.navigateService.reloadPage();
  }

  /**
   * Send code to phone number
   *
   * @param phone
   */
  public sendPhoneValidation(phone: string): Observable<unknown> {
    return this.fns
      .httpsCallable('sendPhoneValidation')({phone})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   * Validate code and phone
   *
   * @param phone
   * @param code
   * @param storeId The store id the user used this in
   * @param language
   * @param nationality
   */
  public sendCode(
    phone: string,
    code: string,
    storeId: string | undefined,
    language: string,
    nationality: string
  ): Observable<'OK'> {
    return this.fns
      .httpsCallable('validatePhone')({phone, code, storeId, language, nationality})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   * SignUp user with email and password and validate phone and code
   *
   * @param email
   * @param password
   * @param fullName
   * @param phoneNumber
   * @param code (obsolete)
   * @param storeId The store id the user registered from
   * @param language
   * @param nationality
   */
  public /*validateCodeAnd*/ saveUser(
    email: string,
    password: string,
    fullName: string,
    phoneNumber: string,
    // code: string,
    storeId: string | undefined,
    language: string,
    nationality: string
  ): Observable<any> {
    return this.fns
      .httpsCallable('registerWithEmail')({
        email,
        password,
        name: fullName,
        phone: phoneNumber,
        // code,
        language,
        nationality,
        storeId,
      })
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /***
   *Get all user orders
   * @param userId
   */
  public getOrdersForUser(userId: string): Observable<QuerySnapshot<OrderModel>> {
    return this.firestore
      .collection<OrderModel>('orders', (ref) =>
        ref.where('userId', '==', userId).where('isConfirmed', '==', true)
      )
      .get()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   * Get first 10 user's sessions from user sub collection
   *
   * @param userId
   */
  public getUserSessions(userId: string): Observable<QuerySnapshot<DbUserSessionsModel>> {
    return this.firestore
      .collection(`users`)
      .doc(userId)
      .collection<DbUserSessionsModel>('userSessions', (ref) =>
        ref.orderBy('hasEnded', 'asc').orderBy('startTime', 'asc').limit(5)
      )
      .get()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   *  Get more 10 user's sessions from user sub collection
   *
   * @param userId
   * @param lastVisibleSessions
   */
  public getUserSessionsLimit(
    userId: string,
    lastVisibleSessions: UserSessionsModel
  ): Observable<QuerySnapshot<DbUserSessionsModel>> {
    return this.firestore
      .collection(`users`)
      .doc(userId)
      .collection<DbUserSessionsModel>('userSessions', (ref) =>
        ref
          .orderBy('hasEnded', 'asc')
          .orderBy('startTime', 'asc')
          .startAfter(lastVisibleSessions)
          .limit(5)
      )
      .get()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   * Get sub collection paymentMethods of user
   *
   * @param userId
   */
  public getUserPaymentMethods(userId: string): Observable<QuerySnapshot<UserPaymentMethodModel>> {
    return this.firestore
      .collection('users')
      .doc(userId)
      .collection<UserPaymentMethodModel>('paymentMethods')
      .get()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   * Get sub collection addresses of user
   *
   * @param userId
   */
  @CacheAbleShare()
  public getUserAddresses(userId: string): Observable<QuerySnapshot<DbAddressModel>> {
    return this.firestore
      .collection('users')
      .doc(userId)
      .collection<DbAddressModel>('addresses')
      .get()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   *
   * @param address The updated addresss
   */
  public updateUserAddress(address: DbAddressModel) {
    return this.firestore
      .collection('users')
      .doc(address.userId)
      .collection('addresses')
      .doc(address.id)
      .update(address);
  }

  // /users/{userId}/addresses/{addressId}
  public addUserAddress(userId: string, address: DbAddressModel) {
    const addressRef = this.firestore.collection('users').doc(userId).collection('addresses').doc();

    address.id = addressRef.ref.id;
    return addressRef.set(address);
  }

  public deleteUserAddress(userId: string, addressId: string) {
    return this.firestore
      .collection('users')
      .doc(userId)
      .collection('addresses')
      .doc(addressId)
      .delete();
  }

  /**
   *
   * Get user personla info
   *
   * @param userId
   */
  public getUserInfo(userId: string): Observable<DocumentSnapshot<UserProfileModel>> {
    return this.firestore
      .collection('users')
      .doc<DbUserModel>(userId)
      .get()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   * Get card SetupIntent from server to save user card for later use
   */
  public getCardIntent(storeId: string): Observable<PaymentDataStripe | undefined> {
    return this.fns
      .httpsCallable('getCardIntent')({storeId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   * Saves user credit card to DB after successful SetupIntent
   *
   * @param token
   * @param supplier
   */
  public saveCardForUser(
    token: string,
    supplier: PaymentSuppliers
  ): Observable<UserPaymentMethodModel> {
    return this.fns
      .httpsCallable('saveCardForUser')({
        token,
        supplier,
      })
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   * Deletes user payment method
   *
   * @param paymentMethodId
   */
  public deleteUserPaymentMethod(paymentMethodId: string): Observable<unknown> {
    return this.fns
      .httpsCallable('removeCardForUser')({paymentMethodId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   *
   * @param userId
   * @param file
   */
  public uploadUserPhoto(userId: string, file: File): Observable<string> {
    return this.storage.uploadFileToStorage(
      `userImages/${userId}.${FileHelpers.getFileExtension(file)}`,
      file,
      '.jpg,.png,.jpeg',
      5
    );
  }

  /**
   *
   * @param photoUrl
   */
  public updateUserPhoto(photoUrl: string): Observable<unknown> {
    return this.fns
      .httpsCallable('updateUserPhoto')({photoUrl})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   * Updates user personal info
   *
   * @param userId
   * @param fullName
   * @param nationality
   * @param dateOfBirth
   * @param sex
   * @param country
   * @param birthDate
   * @param gender
   * @param email
   * @param phone
   */
  public updateUserInfo(
    fullName: string,
    country: string,
    birthDate: string,
    gender: string,
    email?: string,
    phone?: string
  ): Observable<unknown> {
    return this.updateUserInfoEmail(email).pipe(
      switchMap(async (user) => {
        if (fullName) user?.updateProfile({displayName: fullName});
        return user;
      }),
      switchMap(() =>
        this.fns
          .httpsCallable('updateUserInfo')({fullName, country, birthDate, gender, email, phone})
          .pipe(
            catchError((e) => this.languageService.translateError(e)),
            retry({count: 3, delay: 300, resetOnSuccess: true})
          )
      )
    );
  }

  /**
   * Updates user system language
   *
   * @param language
   */
  public updateUserGeoData(language: string): Observable<any> {
    return this.fns
      .httpsCallable('updateUserGeoData')({language})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   * Updates user info email
   *
   * @param email
   */
  public updateUserInfoEmail(email: string | undefined) {
    return from(this.afAuth.currentUser).pipe(
      switchMap(async (user) => {
        if (email) await user?.updateEmail(email);
        return user;
      })
    );
  }

  public sendUserResetPassword(email: string, storeId: string | undefined): Observable<'OK'> {
    return this.fns
      .httpsCallable('sendUserResetPassword')({email, storeId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public verifyPasswordResetCode(code: string): Observable<string> {
    return from(this.afAuth.verifyPasswordResetCode(code));
  }

  public completeResetPassword(code: string, newPassword: string): Observable<void> {
    return from(this.afAuth.confirmPasswordReset(code, newPassword));
  }
  @CacheAbleShare()
  public getGeoLocationData(): Observable<string> {
    return this.fns
      .httpsCallable('getUserGeoDataV2')({})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public getUserIp(): Observable<any> {
    return this.http.get('http://api.ipify.org/?format=json');
  }
}
