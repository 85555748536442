import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, filter, map} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../modals/confirmation-modal/confirmation-modal.component';
import {HttpClient} from '@angular/common/http';
import {ActivationEnd, NavigationEnd, Router} from '@angular/router';
import {ToggleReminderModalComponent} from '../session/client/toggle-reminder-modal/toggle-reminder-modal.component';
import {StateHolderService} from './state-holder.service';

const InteractionEvents = ['scroll', 'keydown', 'click', 'touchend'];

const isSessionRoute = (url: string) => url.includes('session') && !url.includes('manage');

const calculateIsMobile = () => window.innerWidth < 1280;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private modalService = inject(NgbModal);
  public http = inject(HttpClient);
  private router = inject(Router);
  private stateService = inject(StateHolderService);

  private calculateIsMobile() {
    this.updateIsMobile(calculateIsMobile());
  }
  // Is Mobile
  public isMobile = new BehaviorSubject(calculateIsMobile());
  public isMobileChanged = this.isMobile.asObservable();

  public showLoader = new BehaviorSubject(true);

  // Header Status
  private headerStatusSubject = new BehaviorSubject(!isSessionRoute(this.router.url));
  public headerStatusChanged = this.headerStatusSubject.asObservable();

  // Back to store button
  private currentState = false;
  public backToStoreChanged = this.router.events.pipe(
    map((event) => {
      if (event instanceof NavigationEnd) this.currentState = event.url.includes('manage');

      if (event instanceof ActivationEnd) {
        this.currentState = event.snapshot.url.some((segment) => segment.path === 'manage');
      }

      return this.currentState;
    })
  );

  constructor() {
    InteractionEvents.forEach((typeOfInteraction) => {
      document.body.addEventListener(typeOfInteraction, this.onUserInteractionUnbind, {
        once: true,
        passive: true,
        capture: true,
      });
    });
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        isSessionRoute(this.router.url)
          ? this.updateHeaderStatus(false)
          : this.updateHeaderStatus(true);
      }
    });
    addEventListener('resize', () => {
      this.calculateIsMobile();
    });
    this.calculateIsMobile();
    this.stateService.hasUserInteracted$
      .pipe(filter((state) => state))
      .subscribe(() => this.stateService.isMuted$.next(false));
  }

  onUserInteractionUnbind = () => {
    this.onUserInteraction();
  };

  private onUserInteraction() {
    this.stateService.hasUserInteracted$.next(true);
    this.stateService.hasUserInteracted$.complete();

    InteractionEvents.forEach((typeOfInteraction) => {
      document.body.removeEventListener(typeOfInteraction, this.onUserInteractionUnbind);
    });
  }

  public updateIsMobile(isMobile: boolean) {
    this.isMobile.next(isMobile);
  }

  private updateHeaderStatus(status: boolean) {
    this.headerStatusSubject.next(status);
  }

  public showConfirmationModal(
    title: string,
    description: string,
    yesButtonText = 'Yes',
    noButtonText = 'No'
  ): Promise<boolean> {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      windowClass: 'confirmation-modal',
      centered: true,
    });

    (modal.componentInstance as ConfirmationModalComponent).title = title;
    (modal.componentInstance as ConfirmationModalComponent).description = description;
    (modal.componentInstance as ConfirmationModalComponent).yesButtonText = yesButtonText;
    (modal.componentInstance as ConfirmationModalComponent).noButtonText = noButtonText;

    // modal is throwing error on dismiss, so we are catching it here as false
    return modal.result.catch(() => false) as Promise<boolean>;
  }

  public async showReminderToggleModal(sessionId: string, prevRemindedState: boolean) {
    const modal = this.modalService.open(ToggleReminderModalComponent, {
      windowClass: 'toggle-reminder-modal',
      centered: true,
    });

    (modal.componentInstance as ToggleReminderModalComponent).sessionId = sessionId;
    (modal.componentInstance as ToggleReminderModalComponent).prevRemindedState = prevRemindedState;

    return (await modal.result.catch(() => undefined)) as Promise<unknown>;
  }
}
