import {inject, Injectable, RendererFactory2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Observable, switchMap, throwError, take} from 'rxjs';
import {LoggerService} from './services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private translateService = inject(TranslateService);
  private rendererFactory = inject(RendererFactory2);
  protected _toastr = inject(ToastrService);

  public get currentLang() {
    return this.translateService.currentLang;
  }

  private readonly LOCAL_STORAGE_LANGUAGE_KEY_NAME = 'Lang';
  private supportedLanguages: Record<string, {isRtl: boolean}> = {
    en: {
      isRtl: false,
    },
    es: {
      isRtl: false,
    },
    he: {
      isRtl: true,
    },
  };
  public getSavedLanguage(): string | null {
    let lang: string | null = null;
    try {
      lang = localStorage.getItem(this.LOCAL_STORAGE_LANGUAGE_KEY_NAME);
    } catch (error) {}

    return lang;
  }

  public changeLanguage(lang: string) {
    if (this.supportedLanguages[lang]) {
      this.translateService.use(lang).pipe(take(1)).subscribe();
      try {
        localStorage.setItem(this.LOCAL_STORAGE_LANGUAGE_KEY_NAME, lang);
      } catch (error) {}

      const renderer2 = this.rendererFactory.createRenderer(null, null);
      const scrollBars = document.getElementsByClassName('ng-scrollbar-wrapper');
      if (this.supportedLanguages[lang].isRtl) {
        if (scrollBars.length) {
          for (let i = 0; i < scrollBars.length; i++) {
            renderer2.setAttribute(scrollBars[i], 'dir', 'rtl');
          }
        }

        renderer2.addClass(document.body, 'rtl');
        renderer2.setAttribute(document.body, 'dir', 'rtl');
      } else {
        renderer2.removeClass(document.body, 'rtl');
        renderer2.setAttribute(document.body, 'dir', 'ltr');
        if (scrollBars.length) {
          for (let i = 0; i < scrollBars.length; i++) {
            renderer2.setAttribute(scrollBars[i], 'dir', 'ltr');
          }
        }
      }
    }
  }

  public isLanguageRtl(): boolean {
    let isRtl = false;
    const lang = this.getSavedLanguage();

    if (lang) isRtl = this.supportedLanguages[lang].isRtl;

    return isRtl;
  }

  public translateError(err: unknown) {
    if (err instanceof Error) {
      LoggerService.count(`Translate error locally and rethrowing it... ${err.message}`);

      return this.translateService.get(err.message).pipe(
        switchMap((translation: string) =>
          throwError(() => {
            err.message = translation;
            throw err;
          })
        )
      );
    }
    return throwError(() => {
      throw err;
    });
  }
  public translate: (
    key: string | string[],
    interpolateParams?: object | undefined
  ) => Observable<string> = this.translateService.get.bind(this.translateService);
  public translateSync: (key: string | string[], interpolateParams?: object | undefined) => string =
    this.translateService.instant.bind(this.translateService);
  constructor() {
    this.translateService.setDefaultLang('en');
    this.changeLanguage(this.getSavedLanguage() ?? this.translateService.getBrowserLang() ?? 'en');
  }
}
