import {inject, Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import type {
  PaymentData,
  paymentDetailsForCart,
  PaymentResultModel,
} from '../../../../shared/dto-models/payments';
import {tap} from 'rxjs';
import {PostMessengerService} from './sdk/post-messenger.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private fns = inject(AngularFireFunctions);
  private postMessenger = inject(PostMessengerService);

  public pay = (data: PaymentData) =>
    this.fns
      .httpsCallable<PaymentData, PaymentResultModel>('pay')(data)
      .pipe(tap((res) => this.postMessenger.sendPostMessageWithPaymentResult(res)));

  public getRedirectLinkForCartPayment = this.fns.httpsCallable<
    {cartId: paymentDetailsForCart['cartId']},
    PaymentResultModel
  >('getRedirectLinkForCartPayment');

  public finalizeStripePay = this.fns.httpsCallable<
    {paymentId: string; intentId: string},
    PaymentResultModel
  >('finalizePayForCart');

  public checkPaymentStatus = this.fns.httpsCallable<{paymentId: string}, PaymentResultModel>(
    'checkPaymentStatusForCart'
  );
}
