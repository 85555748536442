export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;
export type Client = {
  clientType: 'terrific' | 't1' | 'claro';
  homePageFeedType?: 'sessions' | 'stores';
};
export type KeyOf<T> = keyof T;
export type PickByType<T, U> = {[P in keyof T as T[P] extends U ? P : never]: T[P]};
export type RTDBObject<T> = T extends string
  ? T | null
  : T extends (infer U)[]
  ? RTDBObject<U>[] | null
  : T extends Object
  ? {[K in keyof T]: RTDBObject<T[K]>}
  : T;

// type Example = {a: string; b: string[]; c: {d: {test: 1; foo: '2'}[]}; e: {}};
// type Result = RTDBObject<Example>;

// src: https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
export function assertUnreachable(x: never): never {
  console.error(`got an value where function should not be called: ${x}`);
  return void 0 as never;
}

export type RequireOne<T, Keys extends keyof T = keyof T> = Omit<T, Keys> &
  {
    [K in keyof T]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Omit<T, Keys> &
  {
    [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];
