import {Injectable, inject} from '@angular/core';
import {
  AngularFirestore,
  CollectionReference as FirestoreCollectionReference,
  AngularFirestoreCollectionGroup as FirestoreCollectionGroupReference,
  DocumentSnapshot,
  QueryFn as QueryCollectionFn,
  Query,
} from '@angular/fire/compat/firestore';
import {
  EMPTY,
  Observable,
  Subject,
  concatMap,
  filter,
  from,
  map,
  of,
  share,
  shareReplay,
  startWith,
  tap,
  switchMap,
  finalize,
} from 'rxjs';

type Settings = {
  isCollectionGroup?: boolean | null;
  numberOfPagesToKeepInMemory?: number;
  pageSize?: number;
};
type ActualSettings = Required<Settings>;
type NextPage<T> = {startAfter: DocumentSnapshot<T>};
type PrevPage<T> = {endBefore: DocumentSnapshot<T>};
type RefreshPage = {startAfter: undefined; endBefore: undefined};
type Page<T> = NextPage<T> | PrevPage<T> | RefreshPage;
type CollectionReference<T> =
  | FirestoreCollectionReference<T>
  | FirestoreCollectionGroupReference<T>;
type Paginator<T> = {
  docs$: Observable<T[]>;
  nextPage: () => void;
  prevPage: () => void;
  refresh: () => void;
  reset: () => void;
};

type PageRequestDirection = 'next' | 'previous';

const MINIMUM_NUMBER_Of_PAGES_TO_KEEP_IN_MEMORY = 6;
const MINIMUM_NUMBER_OF_DOCS_IN_PAGE = 30;

@Injectable({
  providedIn: 'root',
})
export class FirestorePaginatorService {
  private firestore = inject(AngularFirestore);

  getPaginator<T>(
    collection: string,
    queryFn: Query<T> | QueryCollectionFn<T>,
    config?: Settings
  ): Paginator<T> {
    const {isCollectionGroup, numberOfPagesToKeepInMemory, pageSize} = validateSettings(config);

    const collectionRef = getCollection<T>(this.firestore, collection, isCollectionGroup);
    const baseQuery = getQueryFromQueryFn(collectionRef, queryFn);
    const pagesInMemory = new Map<number, DocumentSnapshot<T>[]>();
    let firstPageLoaded = false;
    let loading = false;

    const requestPage$ = new Subject<PageRequestDirection>();
    const refresh$ = new Subject<'refresh'>();
    const skipDirection = {
      next: false,
      previous: false,
      first: false,
    };

    const docPageResponse$ = requestPage$.pipe(
      startWith('next' as const),
      concatMap((direction) => {
        if (skipDirection[direction]) return EMPTY;
        if (!firstPageLoaded && direction === 'previous') return EMPTY;
        if (loading) return EMPTY;
        loading = true;

        if (pagesInMemory.size === 0) {
          return getPageData(pageSize, baseQuery).pipe(
            tap((docs) => addPageToMemory(pagesInMemory, 0, docs))
          );
        }

        const {page, requestedPageNumber} = getPageAndRequestedPageNumber(
          pagesInMemory,
          pageSize,
          direction
        );
        if (isRefreshPage(page)) {
          return of([]);
        }

        if ((!isNextPage(page) && !isPreviousPage(page)) || isNaN(requestedPageNumber)) {
          return EMPTY;
        }

        return getPageData(pageSize, baseQuery, {page}).pipe(
          filter((docs) => {
            const isEmpty = docs.length === 0;
            if (!isEmpty && direction === 'next') {
              skipDirection.previous = false;
            }
            if (!isEmpty && direction === 'previous') {
              skipDirection.next = false;
            }

            return !isEmpty;
          }),
          tap((docs) => addPageToMemory(pagesInMemory, requestedPageNumber, docs)),
          tap(() => {
            if (pagesInMemory.size > numberOfPagesToKeepInMemory) {
              const pageToRemove =
                direction === 'next' ? firstPage(pagesInMemory) : lastPage(pagesInMemory);
              removePageFromMemory(pagesInMemory, pageToRemove);
            }
          })
        );
      }),
      switchMap((docs) => {
        return refresh$.pipe(
          startWith('refresh' as const),
          map(() => docs)
        );
      }),
      shareReplay({refCount: true, bufferSize: 1})
    );

    const filteredResponse$ = docPageResponse$.pipe(
      map(() => allDocs(pagesInMemory)),
      tap((docs) => recalculatePagesInMemory([...docs], pagesInMemory, pageSize)),
      tap(() => {
        const page = lastPage(pagesInMemory);
        const pageContents = pagesInMemory.get(page);
        const pageIsFull = pageContents && pageContents.length >= pageSize;
        if (pageIsFull && pagesInMemory.size < numberOfPagesToKeepInMemory) {
          setTimeout(() => requestPage$.next('next'), 0);
        }
        loading = false;
      }),
      map((docs) => docs.map((doc) => doc.data()!)),
      shareReplay({refCount: true, bufferSize: 1}),
      finalize(() => {
        console.log('finalizing');
        pagesInMemory.clear();
        requestPage$.complete();
        refresh$.complete();
      })
    );

    const nextPage = () => {
      requestPage$.next('next');
    };
    const prevPage = () => {
      requestPage$.next('previous');
    };
    const refresh = () => {
      refresh$.next('refresh');
    };
    const reset = () => {
      skipDirection.next = false;
      skipDirection.previous = false;
      pagesInMemory.clear();
      requestPage$.next('next');
      refresh();
      // TODO: add a way to reset without requesting the pages again
    };

    const docs$ = filteredResponse$;

    return {
      docs$,
      nextPage,
      prevPage,
      refresh,
      reset,
    };
  }
}

function getPageAndRequestedPageNumber<T>(
  pagesInMemory: Map<number, DocumentSnapshot<T>[]>,
  pageSize: number,
  pageDirection: PageRequestDirection
) {
  const page = getPageFromDirection(pagesInMemory, pageDirection);
  const requestedPageNumber = getPageNumber(pagesInMemory, pageSize, page);
  return {page, requestedPageNumber};
}

function getPageFromDirection<T>(
  pagesInMemory: Map<number, DocumentSnapshot<T>[]>,
  page: PageRequestDirection
): Page<T> {
  switch (page) {
    case 'next':
      return {startAfter: lastDoc(pagesInMemory)} as NextPage<T>;
    case 'previous':
      return {endBefore: firstDoc(pagesInMemory)} as PrevPage<T>;
    default:
      return {startAfter: undefined, endBefore: undefined} as RefreshPage;
  }
}

function getPageNumber<T>(
  pagesInMemory: Map<number, DocumentSnapshot<T>[]>,
  pageSize: number,
  page: Page<T>
) {
  if (isNextPage(page)) {
    const page = lastPage(pagesInMemory);
    const docs = pagesInMemory.get(page) ?? [];
    if (docs.length < pageSize) return page;
    return page + 1;
  }
  if (isPreviousPage(page)) {
    const page = firstPage(pagesInMemory);
    const docs = pagesInMemory.get(page) ?? [];
    if (docs.length < pageSize) return page;
    return page - 1;
  }
  return NaN;
}

function firstDoc<T>(pagesInMemory: Map<number, DocumentSnapshot<T>[]>) {
  const page = firstPage(pagesInMemory);
  const docs = pagesInMemory.get(page);
  return docs?.at(0);
}

function lastDoc<T>(pagesInMemory: Map<number, DocumentSnapshot<T>[]>) {
  const page = lastPage(pagesInMemory);
  const docs = pagesInMemory.get(page);
  return docs?.at(-1);
}

function firstPage<T>(pagesInMemory: Map<number, DocumentSnapshot<T>[]>) {
  return Math.min(...listOfPagesInMemory(pagesInMemory));
}

function lastPage<T>(pagesInMemory: Map<number, DocumentSnapshot<T>[]>) {
  return Math.max(...listOfPagesInMemory(pagesInMemory));
}

function listOfPagesInMemory<T>(pagesInMemory: Map<number, DocumentSnapshot<T>[]>) {
  return Array.from(pagesInMemory.keys());
}

function allDocs<T>(pagesInMemory: Map<number, DocumentSnapshot<T>[]>) {
  return Array.from(pagesInMemory.entries()).reduce((acc, curr) => {
    return acc.concat(curr[1]);
  }, [] as DocumentSnapshot<T>[]);
}

type ValidateSettingFn<T extends keyof Settings> = (settings: Settings) => {
  [key in T]: ActualSettings[T];
};
type ValidateSettingsObj = {
  [key in keyof ActualSettings]: ValidateSettingFn<key>;
};

// edit the settings object to make sure the old value is not used by mistake
const validateSetting: ValidateSettingsObj = {
  pageSize: (settings) => {
    const setting = 'pageSize';
    const defaultValue = defaultSettings[setting];
    const value = Math.max(settings[setting] || 0, defaultValue);
    settings[setting] = value;
    return {[setting]: value};
  },
  numberOfPagesToKeepInMemory: (settings) => {
    const setting = 'numberOfPagesToKeepInMemory';
    const defaultValue = defaultSettings[setting];
    const value = Math.max(settings[setting] || 0, defaultValue);
    settings[setting] = value;
    return {[setting]: value};
  },
  isCollectionGroup: (settings) => {
    const setting = 'isCollectionGroup';
    const defaultValue = defaultSettings[setting];
    const value = settings[setting] ?? defaultValue;
    settings[setting] = value;
    return {[setting]: value};
  },
};

const defaultSettings: ActualSettings = {
  pageSize: MINIMUM_NUMBER_OF_DOCS_IN_PAGE,
  numberOfPagesToKeepInMemory: MINIMUM_NUMBER_Of_PAGES_TO_KEEP_IN_MEMORY,
  isCollectionGroup: null,
};
const settingKeys: (keyof Settings)[] = [
  'pageSize',
  'numberOfPagesToKeepInMemory',
  'isCollectionGroup',
];

// to make sure the pages are big enoughs to get a scroller
function validateSettings(config: Settings = {}): ActualSettings {
  const actualSettings: Settings = {};
  settingKeys.forEach((key) => {
    const setting = validateSetting[key](config);
    Object.assign(actualSettings, setting);
  });
  return actualSettings as ActualSettings;
}

function getQueryResponse<T>(query: Query<T>) {
  return from(query.get()).pipe(map((snapshot) => snapshot.docs as DocumentSnapshot<T>[]));
}

function getCollection<T>(
  firestore: AngularFirestore,
  collection: string | CollectionReference<T>,
  isCollectionGroup?: boolean | null
) {
  if (typeof collection !== 'string') {
    return collection;
  }
  if (isCollectionGroup) {
    return firestore.collectionGroup<T>(collection) as FirestoreCollectionGroupReference<T>;
  } else {
    return firestore.collection<T>(collection).ref as FirestoreCollectionReference<T>;
  }
}

function isNextPage<T>(page?: Page<T>): page is NextPage<T> {
  return !!page && 'startAfter' in page && !!page.startAfter;
}

function isPreviousPage<T>(page?: Page<T>): page is PrevPage<T> {
  return !!page && 'endBefore' in page && !!page.endBefore;
}
function isRefreshPage(page?: Page<any>): page is RefreshPage {
  return !page || (!isNextPage(page) && !isPreviousPage(page));
}

function isCollectionGroup<T>(
  collection: CollectionReference<T>
): collection is FirestoreCollectionGroupReference<T> {
  return !!collection && 'get' in collection && !isCollectionReference(collection);
}

function isCollectionReference<T>(
  collection: CollectionReference<T>
): collection is FirestoreCollectionReference<T> {
  return !!collection && 'doc' in collection;
}

function getQueryFromQueryFn<T>(
  collection: CollectionReference<T>,
  queryFn: Query<T> | QueryCollectionFn<T>
): Query<T> {
  if ('where' in queryFn || isCollectionGroup(collection)) {
    return queryFn as Query<T>;
  } else if (isCollectionReference(collection)) {
    return (queryFn as QueryCollectionFn<T>)(collection);
  }
  throw new Error('Invalid collection reference');
}

function getPageQuery<T>(pageSize: number, query: Query<T>, page?: Page<T>): Query<T> {
  if (isNextPage(page)) {
    return query.startAfter(page.startAfter).limit(pageSize);
  } else if (isPreviousPage(page)) {
    return query.endBefore(page.endBefore).limitToLast(pageSize);
  } else {
    return query.limit(pageSize);
  }
}

function getPageData<T>(
  pageSize: number,
  query: Query<T>,
  config?: {
    page?: Page<T>;
  }
): Observable<DocumentSnapshot<T>[]> {
  const pageQuery = getPageQuery(pageSize, query, config?.page);
  return getQueryResponse(pageQuery);
}

function addPageToMemory<T>(
  pagesInMemory: Map<number, DocumentSnapshot<T>[]>,
  requestedPageNumber: number,
  docs: DocumentSnapshot<T>[]
): void {
  if (!Array.isArray(docs)) {
    throw new Error('Invalid page');
  }
  const currentPageData = pagesInMemory.get(requestedPageNumber) ?? [];
  pagesInMemory.set(requestedPageNumber, currentPageData.concat(docs));
}

function removePageFromMemory<T>(
  pagesInMemory: Map<number, DocumentSnapshot<T>[]>,
  pageToRemove: number
) {
  pagesInMemory.delete(pageToRemove);
}

function recalculatePagesInMemory<T>(
  docs: DocumentSnapshot<T>[],
  pagesInMemory: Map<number, DocumentSnapshot<T>[]>,
  pageSize: number,
  excludeDocs?: Set<string>
): void {
  docs = docs.filter((doc) => doc.exists);
  const filteredDocs = docs.filter((doc) => !excludeDocs?.has(doc.id));
  const numberOfDocs = filteredDocs.length;
  const newNumberOfPages = Math.ceil(numberOfDocs / pageSize);

  pagesInMemory.clear();

  // set first n-1 pages, last page will be all docs left
  // this allows us to keep paging behavior with filtered out docs
  for (let i = 0; i < newNumberOfPages - 1; i++) {
    addPageToMemory(pagesInMemory, i, docs.splice(0, pageSize));
  }

  // add last page
  addPageToMemory(pagesInMemory, newNumberOfPages - 1, docs);
}
