import type firebase from 'firebase';
type Timestamp = firebase.firestore.Timestamp;

type LiveData<T extends {stock: number | null} = {stock: number | null}> = T;

export class DbProductModel {
  id: string;
  storeId: string = '';
  name: string = '';
  description: string = '';
  shortDescription: string = ''; // Auto calculated
  sku: string = '';
  barcode: string = '';
  price: number;
  compareAtPrice: number | null = null;
  isDeleted: boolean = false;
  createDate: Timestamp;
  lastUpdateDate: Timestamp;

  imageUrls: string[];
  images: DbProductImageModel[];
  options: DbProductOptionModel[];
  shippingMethodIds: string[];

  // External
  itemGettingSyncedFromExternalSource = false;
  lastExternalUpdateDate: Timestamp | null = null;
  externalId: string | null = null;
  externalUrl: string | null = null;
  integrationDocId: string | null = null;
  integrationType: string | null = null;

  // Customization
  isCustomized: boolean = false;
  customType: CustomType = CustomType.None;
  customizationText: ProductCustomizationText | null = null;
  customizationImage: ProductCustomizationImage | null = null;

  constructor() {
    this.options = [];
    this.images = [];
    this.imageUrls = [];
    this.shippingMethodIds = [];
    this.customizationText = new ProductCustomizationText();
    this.customizationImage = new ProductCustomizationImage();
  }
}

export class DbProductImageModel {
  id: string;
  src: string;
  createDate: Timestamp;
  lastUpdateDate: Timestamp;
  externalId?: string;
}

export class DbProductOptionModel {
  option: string;
  values: string[];
}

export class DbProductVariantModel {
  id?: string;
  storeId: string;
  productId: string;
  optionValues: {[option: string]: string};
  price: number;
  compareAtPrice: number | null = null;
  exclude: boolean = false;
  isDeleted: boolean = false;
  orderIndex: number;

  externalId: string | null;
  externalUrl: string | null;

  imageIds: string[] = [];
  imageUrls: string[] = [];
  sku?: string;
  // From Product
  shortDescription: string = '';
  shippingMethodIds: string[];

  liveData: LiveData | undefined;
}

export class ProductCustomizationText {
  explanationField: string;
  explanationFieldLabel: string;
  explanationFieldHint: string;
  maxCharactersAllow: number;
}

export class ProductCustomizationImage {
  explanationField: string;
  explanationFieldLabel: string;
  imageMinimumWidth: number;
  imageMinimumHeight: number;
  imageMaximumHeight: number;
  imageMaxWidth: number;
}

export class CustomImagePreView {
  imageUrl: string;
  productId: string;
  variantId: string;
  file?: File;
}

export enum CustomType {
  Text = 1,
  Image = 2,
  None = 3,
}
