import {AfterViewInit, Component, inject, OnInit, RendererFactory2} from '@angular/core';
import {Location} from '@angular/common';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Router, Event as NavigationEvent, NavigationEnd} from '@angular/router';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe-decorator';
import {DeviceDetectorService} from 'ngx-device-detector';
import {
  first,
  retry,
  fromEvent,
  timer,
  take,
  switchMap,
  Subscription,
  filter,
  combineLatest,
} from 'rxjs';
import {environment} from 'src/environments/environment';
import {
  PlatformMediaCustomableName,
  PlatformMediaFile,
  PlatformTextProperties,
  PlatformTextPropertiesName,
} from '../../../shared/db-models/media';
import {updateDefaultTheme} from './helpers/customization-ui-helpers';
import {LanguageService} from './language.service';
import {LoggerService} from './services/logger.service';
import {NavigateService} from './services/navigate.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  protected languageService = inject(LanguageService);
  protected fs = inject(AngularFirestore);
  private location = inject(Location);
  public videoPlaceHolderName = PlatformMediaCustomableName.VIDEO_LOADER;

  public appIsInitializing = true;
  @AutoUnsubscribe()
  routerEvents$: Subscription;

  public isHomePage = this.location.path() === '';

  private navigateService = inject(NavigateService);
  private rendererFactory = inject(RendererFactory2);
  private router = inject(Router);
  protected deviceService = inject(DeviceDetectorService);

  @AutoUnsubscribe()
  private viewportResizeEvent$: Subscription | undefined;
  private viewportRecalculationTime = 10;
  private viewportAnimationTime = 350;
  private height: number = window.visualViewport?.height ?? window.innerHeight;

  ngOnInit() {
    const renderer2 = this.rendererFactory.createRenderer(null, null);
    renderer2.setAttribute(document.body, 'client-name', environment.client.clientType);

    combineLatest(
      this.fs
        .doc<PlatformTextProperties>('media/textProperties')
        .valueChanges()
        .pipe(retry({count: 3, delay: 300, resetOnSuccess: true})),
      // Wait until page will be loaded
      this.router.events.pipe(filter((event: NavigationEvent) => event instanceof NavigationEnd))
    ).subscribe({
      next: ([data, navigationEvent]) => {
        updateDefaultTheme({
          primaryColor: data?.[PlatformTextPropertiesName.PRIMARY_COLOR]?.value,
          secondaryColor: data?.[PlatformTextPropertiesName.SECONDARY_COLOR]?.value,
          lightColor: data?.[PlatformTextPropertiesName.LIGHT_COLOR]?.value,
        });
        document.title =
          data?.[PlatformTextPropertiesName.TITLE]?.value ??
          (environment.client.clientType === 'terrific' ? 'Terrific' : '');
        const store = data?.[PlatformTextPropertiesName.DEFAULT_ACTIVE_STORE]?.value;
        this.navigateService.globalStoreUrl$.next(store ?? null);
        document.getElementById('main-loader')?.remove();
        document.getElementById('main-loader-script')?.remove();
        document.getElementById('main-loader-style')?.remove();
      },
      complete: () => {
        this.navigateService.globalStoreUrl$.next(this.navigateService.globalStoreUrl$.value);
        document.getElementById('main-loader')?.remove();
        document.getElementById('main-loader-script')?.remove();
        document.getElementById('main-loader-style')?.remove();
      },
      error: (e) => {
        LoggerService.error('error', e);
        document.getElementById('main-loader')?.remove();
        document.getElementById('main-loader-script')?.remove();
        document.getElementById('main-loader-style')?.remove();
      },
    });
    this.fs
      .doc<PlatformMediaFile>('media/' + PlatformMediaCustomableName.FAVICON)
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}))
      .subscribe({
        next: (data) => {
          if (!data && environment.client.clientType !== 'terrific') return;
          let link: any = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
          }
          link.href = data ? data.url : 'favicon.ico';
        },
        error: (e) => {
          LoggerService.error('error', e);
        },
      });
    LoggerService.log('init app');

    this.routerEvents$ = this.router.events
      .pipe(
        filter((event: NavigationEvent) => event instanceof NavigationEnd),
        first()
      )
      .subscribe(() => {
        this.appIsInitializing = false;
      });
  }

  ngAfterViewInit(): void {
    // Calculate true ViewPort height
    if (!this.deviceService.isDesktop()) {
      this.calculateViewport();
    }
  }

  setViewHeightBasedOnVisualViewport() {
    const baseHeight = window.visualViewport?.height ?? window.innerHeight;
    const vh = baseHeight * 0.01;
    const previousVh = parseFloat(document.documentElement.style.getPropertyValue('--vh')) ?? 0.0;
    if (vh === previousVh) return;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (((this.height - baseHeight) ** 2) ** 0.5 < 200) return;
    document.dispatchEvent(new Event('resized'));
    this.height = baseHeight;
  }

  calculateViewport(): void {
    if (visualViewport) {
      // In safari resize event is fired after keyboard is already hidden
      // We can use for Safari document.body.addEventListener('blur', () => {}, true) handler
      // but until keyboard is hidden it returns old height
      this.viewportResizeEvent$ = fromEvent(visualViewport, 'resize')
        .pipe(
          switchMap(() => {
            return timer(0, this.viewportRecalculationTime).pipe(
              take(this.viewportAnimationTime / this.viewportRecalculationTime)
            );
          })
        )
        .subscribe(() => {
          this.setViewHeightBasedOnVisualViewport();
        });
    }
  }
}
