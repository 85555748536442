import {inject, Injectable} from '@angular/core';
import {defer, Observable, of, switchMap} from 'rxjs';
import {UsersService} from './users.service';
import {AngularFirestore, QuerySnapshot} from '@angular/fire/compat/firestore';
import {DbSharedLinkModel, SharedLinkTarget} from 'terrific-shared/db-models/links';
import TimestampHelper from '../helpers/timestamp-helper';
import {retry} from 'rxjs/operators';
import {LogService} from '../logger/logger.service';
import {LogLevel} from '../logger/logger-level.enum';

@Injectable({
  providedIn: 'root',
})
export class DynamicLinksService {
  private usersService = inject(UsersService);
  private firestore = inject(AngularFirestore);
  private loggerService = inject(LogService);

  public createDynamicLink(path: string, target: SharedLinkTarget) {
    const userId = this.usersService.connectedUserSync.uid;
    this.loggerService.logMessage(
      LogLevel.DEBUG,
      `createDynamicLink, userId: ${userId}, target:`,
      target
    );
    return this.firestore
      .collection<DbSharedLinkModel>('links', (ref) =>
        ref
          .where('refererUserId', '==', userId)
          .where('path', '==', path)
          .where('target.type', '==', target.type)
          .where('target.id', '==', target.id)
      )
      .get()
      .pipe(
        switchMap((links) => {
          return links.empty
            ? this.createNewLink(userId, path, target)
            : of(this.takeEarliest(links));
        })
      );
  }

  public buildFullUrl(link: DbSharedLinkModel, origin: string) {
    return new URL(`link/${link.id}`, origin).href;
  }

  private createNewLink(
    userId: string,
    path: string,
    target: SharedLinkTarget
  ): Observable<DbSharedLinkModel> {
    this.loggerService.logMessage(
      LogLevel.DEBUG,
      `create new link by user '${userId}' with path '${path}' for target`,
      target
    );
    return defer(async () => {
      const link: DbSharedLinkModel = {
        id: this.generateLinkId(),
        refererUserId: userId,
        path,
        target,
        createAt: TimestampHelper.getCurrentDateTimestamp(),
      };
      await this.firestore.doc<DbSharedLinkModel>(`links/${link.id}`).set(link);
      return link;
    }).pipe(retry(5));
  }

  private takeEarliest(links: QuerySnapshot<DbSharedLinkModel>) {
    return links.docs
      .sort((a, b) => a.data().createAt.toMillis() - b.data().createAt.toMillis())[0]
      .data();
  }

  private generateLinkId() {
    return this.firestore.createId();
  }
}
