import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injector, NgModule, inject} from '@angular/core';
import {AppComponent} from './app.component';
import {ToastrModule} from 'ngx-toastr';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxStripeModule} from 'ngx-stripe';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BaseModule} from './base-module/base.module';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  AngularFireAnalyticsModule,
  APP_NAME,
  APP_VERSION,
  DEBUG_MODE as ANALYTICS_DEBUG_MODE,
  ScreenTrackingService,
  UserTrackingService,
  COLLECTION_ENABLED,
} from '@angular/fire/compat/analytics';

import {
  AngularFireDatabaseModule,
  USE_EMULATOR as USE_DATABASE_EMULATOR,
} from '@angular/fire/compat/database';
import {
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
  SETTINGS as FIRESTORE_SETTINGS,
} from '@angular/fire/compat/firestore';
import {
  AngularFireStorageModule,
  USE_EMULATOR as USE_STORAGE_EMULATOR,
} from '@angular/fire/compat/storage';
import {
  AngularFireAuthModule,
  USE_DEVICE_LANGUAGE,
  USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/compat/auth';
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/compat/functions';
// import {
//   AngularFireRemoteConfigModule,
//   SETTINGS as REMOTE_CONFIG_SETTINGS,
//   DEFAULTS as REMOTE_CONFIG_DEFAULTS,
// } from '@angular/fire/compat/remote-config';
// import {
//   AngularFirePerformanceModule,
//   PerformanceMonitoringService,
// } from '@angular/fire/compat/performance';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {
  connectFunctionsEmulator,
  FunctionsModule,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
// import {
//   connectFirestoreEmulator,
//   getFirestore,
//   provideFirestore,
//   enableMultiTabIndexedDbPersistence,
// } from '@angular/fire/firestore';
import {connectDatabaseEmulator, getDatabase, provideDatabase} from '@angular/fire/database';
import {connectStorageEmulator, getStorage, provideStorage} from '@angular/fire/storage';
import {getRemoteConfig, provideRemoteConfig} from '@angular/fire/remote-config';
import {getAnalytics, provideAnalytics} from '@angular/fire/analytics';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {provideAuth, connectAuthEmulator, getAuth} from '@angular/fire/auth';
import {CommonModule} from '@angular/common';
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from 'src/environments/environment';
import {GlobalErrorHandler} from './global-error-handler.provider';
import {LoggerService} from './services/logger.service';
import {SessionService} from './session/shared/session.service';
import {StoresService} from './services/stores.service';
// let resolvePersistenceEnabled: (enabled: boolean) => void;

// export const persistenceEnabled = new Promise<boolean>((resolve) => {
//   resolvePersistenceEnabled = resolve;
// });

enum EMULATOR_PORTS {
  RTDB = 8090,
  FIRESTORE = 8080,
  FUNCTIONS = 5001,
  STORAGE = 9199,
}

/**
 *
 * @param http
 */
function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export let AppInjector: Injector;

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('./app-routing.module').then((m) => {
              return m.AppRoutingModule;
            }),
        },
      ],
      {preloadingStrategy: PreloadAllModules}
    ),
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    NgxPageScrollCoreModule.forRoot({duration: 1000}),
    FormsModule,
    ReactiveFormsModule,
    BaseModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    // AngularFirePerformanceModule,
    // AngularFireRemoteConfigModule,
    AngularFireAuthGuardModule,
    FunctionsModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', {disableWarnings: true});
      }

      return auth;
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // provideFirestore(() => {
    //   const firestore = getFirestore();
    //   if (environment.useEmulators) {
    //     connectFirestoreEmulator(firestore, 'localhost', EMULATOR_PORTS.FIRESTORE);
    //   }
    //   enableMultiTabIndexedDbPersistence(firestore).then(
    //     () => resolvePersistenceEnabled(true),
    //     () => resolvePersistenceEnabled(false)
    //   );
    //   return firestore;
    // }),
    provideDatabase(() => {
      const database = getDatabase();
      if (environment.useEmulators) {
        connectDatabaseEmulator(database, 'localhost', EMULATOR_PORTS.RTDB);
      }

      return database;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, 'localhost', EMULATOR_PORTS.STORAGE);
      }

      return storage;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, 'localhost', EMULATOR_PORTS.FUNCTIONS);
      }

      return functions;
    }),
    NgxStripeModule.forRoot(),
  ],
  exports: [BaseModule],
  providers: [
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ScreenTrackingService,
    UserTrackingService,
    // PerformanceMonitoringService,
    {
      provide: FIRESTORE_SETTINGS,
      useValue: {
        ignoreUndefinedProperties: true,
        merge: true,
        experimentalForceLongPolling: environment.featureFlags.longPolling,
        experimentalAutoDetectLongPolling: !environment.featureFlags.longPolling,
      },
    },
    {provide: ANALYTICS_DEBUG_MODE, useValue: true},
    {provide: COLLECTION_ENABLED, useValue: true},
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.RTDB] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,

      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.FIRESTORE] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.FUNCTIONS] : undefined,
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', EMULATOR_PORTS.STORAGE] : undefined,
    },
    // {
    //   provide: REMOTE_CONFIG_SETTINGS,
    //   useFactory: () => (isDevMode() ? {minimumFetchIntervalMillis: 10_000} : {}),
    // },
    // {provide: REMOTE_CONFIG_DEFAULTS, useValue: {}},
    {provide: USE_DEVICE_LANGUAGE, useValue: true},
    {provide: APP_VERSION, useValue: '1.0.19'},
    {provide: APP_NAME, useValue: 'Client'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  protected sessionService = inject(SessionService);
  protected storesService = inject(StoresService);
  constructor(private injector: Injector) {
    AppInjector = this.injector;
    LoggerService.log('angular started');

    //load session & store & data as soon as possible if located in session
    const regEx = new RegExp(
      /^https:\/\/\w*\.?\w+\.\w+\/(?<storeUrl>\w*)\/?session\/(?<sessionId>[\w\d]+)/gm
    );
    const regExResult = regEx.exec(window.location.href);
    let storeUrl: string;
    let sessionId: string;
    if (regExResult) {
      storeUrl = regExResult[1];
      sessionId = regExResult[2];
      this.storesService.loadActiveStore(storeUrl);
      this.sessionService.getSessionDataDTO(sessionId);
    }
  }
}
