<app-loader *ngIf="isLoading"></app-loader>

<ng-container *ngIf="!isLoading">
  <!-- Close modal button -->
  <button (click)="modal.dismiss()" class="btn-close-modal" type="button">
    <img alt="Close" src="assets/images/icons/close-modal-icon.svg" />
  </button>
  <button (click)="modal.dismiss()" class="btn-close-modal-mobile">
    <img alt="Close" src="assets/images/icons/close-modal-icon-mobile.svg" />
  </button>

  <!-- Title -->
  <div class="icon-wrapper">
    <app-icon [name]="'Notification'" [size]="'56px'" [color]="'var(--primary-store)'"></app-icon>
  </div>
  <h2 class="title text-center">
    {{ 'LOGIN_MODAL.FOMO_ALERT' | translate }}
  </h2>
  <p class="text-center">
    {{ 'LOGIN_MODAL.LEAVE_YOUR_DETAILS_HERE' | translate }}
  </p>
  <app-add-to-calendar [sessionId]="sessionId">
    <div class="calendar">
      <button id="default-calendar-button" class="default-calendar-button">
        <app-icon
          [name]="'Calendar'"
          [color]="'var(--primary-store)'"
          [size]="'20px'"
          class="mx-1"
        ></app-icon>
        {{ 'REMINDER_TOGGLE_MODAL.ADD_TO_CALENDAR' | translate }}
      </button>
    </div>
  </app-add-to-calendar>
  <div class="container-or">
    <div class="line"></div>
    <p>{{ 'REMINDER_TOGGLE_MODAL.OR' | translate }}</p>
    <div class="line"></div>
  </div>
  <!-- // TODO: phone number comp -->
  <form
    class="reminder-form"
    name="reminderForm"
    #reminderForm="ngForm"
    (ngSubmit)="toggleReminder()"
    *ngIf="reminderOptions"
  >
    <div class="form-group">
      <label for="name">{{ 'REMINDER_TOGGLE_MODAL.NAME_TITLE' | translate }}</label>
      <input
        type="text"
        id="name"
        [(ngModel)]="reminderData.name"
        name="name"
        required
        [pattern]="PAT_NAME"
        [autofocus]="true"
        class="custom-input"
        placeholder="{{ 'REMINDER_TOGGLE_MODAL.NAME' | translate }}"
        #nameInput="ngModel"
      />
      <div
        class="invalid-feedback"
        [style.visibility]="nameInput.invalid && nameInput.touched ? 'visible' : 'hidden'"
      >
        {{ 'ERROR.INVALID_NAME' | translate }}
      </div>
    </div>

    <div class="form-group" *ngIf="reminderOptions?.email">
      <label for="email">{{ 'REMINDER_TOGGLE_MODAL.EMAIL_TITLE' | translate }}</label>
      <input
        type="email"
        id="email"
        [(ngModel)]="reminderData.email"
        name="email"
        required
        [pattern]="PAT_EMAIL"
        class="custom-input"
        placeholder="{{ 'REMINDER_TOGGLE_MODAL.EMAIL' | translate }}"
        #emailInput="ngModel"
      />
      <div
        class="invalid-feedback"
        [style.visibility]="emailInput.invalid && emailInput.touched ? 'visible' : 'hidden'"
      >
        {{ 'ERROR.INVALID_EMAIL_ADDRESS' | translate }}
      </div>
    </div>
    <div class="form-group" *ngIf="reminderOptions?.phone">
      <label for="phone-number">{{ 'REMINDER_TOGGLE_MODAL.PHONE_TITLE' | translate }}</label>
      <input
        type="text"
        id="phone-number"
        [(ngModel)]="reminderData.phone"
        name="phone-number"
        required
        [pattern]="PAT_PHONE"
        class="custom-input"
        placeholder="{{ 'REMINDER_TOGGLE_MODAL.PHONE_NUMBER' | translate }}"
        #phoneNumberInput="ngModel"
      />
      <div
        class="invalid-feedback"
        [style.visibility]="
          phoneNumberInput.invalid && phoneNumberInput.touched ? 'visible' : 'hidden'
        "
      >
        {{ 'ERROR.INVALID_PHONE_NUMBER' | translate }}
      </div>
    </div>
    <div class="text-center">
      <app-button
        (clickEmitter)="toggleReminder()"
        [rounded]="true"
        [size]="'small'"
        [disabled]="isSubmitLoading || reminderForm.form.invalid"
        [customStyle]="{
          'height': '54px',
          'width': 'auto',
          'font-size': '18px',
          'color': 'var(--light-store, #fff)',
          'border-radius': '27px',
          'padding': '0 27px'
        }"
      >
        {{ 'REMINDER_TOGGLE_MODAL.NOTIFY_ME' | translate }}
      </app-button>
    </div>
  </form>
</ng-container>
