import {Injectable, Injector, NgZone, inject, isDevMode} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Subject, animationFrameScheduler, map, retry, shareReplay, subscribeOn, tap} from 'rxjs';
import {DbSessionAnalyticsCountersDoc, PollStatus} from '../../../../shared/db-models/session';
import {
  EventName,
  PollEvents,
  SessionEventData,
  ShareEvents,
} from '../../../../shared/db-models/session-events';
import {SessionDataDTO} from '../../../../shared/dto-models/session-data';
import {SessionService} from '../session/shared/session.service';
import {LoggerService} from './logger.service';
import {UsersService} from './users.service';
import TimestampHelper from '../helpers/timestamp-helper';
import {getSessionStateSimplified} from '../../../../shared/types/session';
import {environment} from '../../environments/environment';
import {SessionState} from '../../../../shared/types/session';
declare global {
  interface Window {
    fbq?: (param1: string, param2: any, param3?: any) => any;
    _fbq?: unknown;
  }
}

function initPixel(window: Window, document: Document, script: 'script', scriptUrl: string) {
  if (window.fbq as any) return;

  const facebookAnalytics: any = (window.fbq = function tempFnForEventsEmitted() {
    facebookAnalytics.callMethod
      ? facebookAnalytics.callMethod.apply(facebookAnalytics, arguments)
      : facebookAnalytics.queue.push(arguments);
  });

  if (!window._fbq) window._fbq = facebookAnalytics;

  facebookAnalytics.push = facebookAnalytics;
  facebookAnalytics.loaded = !0;
  facebookAnalytics.version = '2.0';
  facebookAnalytics.queue = [];

  const scriptElement = document.createElement(script);
  scriptElement.async = !0;
  scriptElement.src = scriptUrl;

  const firstScriptOnPage = document.getElementsByTagName(script)[0];
  firstScriptOnPage.parentNode?.insertBefore(scriptElement, firstScriptOnPage);
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  injector = inject(Injector);
  private analytics = inject(AngularFireAnalytics);
  private angularFireFunctions = inject(AngularFireFunctions);
  private firestore = inject(AngularFirestore);
  private ngZone = inject(NgZone);

  public addPixelId = (pixelId: string) => {
    this.ngZone.runOutsideAngular(() => {
      initPixel(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      window.fbq?.('init', pixelId);
      window.fbq?.('track', 'PageView');
    });
  };

  private get user() {
    return this.injector.get(UsersService).connectedUserSync;
  }

  private get sessionService() {
    return this.injector.get(SessionService);
  }

  public logEvent = (
    eventName: string,
    eventParams?: Record<string, any> | undefined,
    options?: any
  ) => {
    return Promise.allSettled([
      this.analytics.logEvent(eventName, eventParams, options).then(
        () => LoggerService.log(eventName + ' analytics log event sended successfully to server'),
        (e) => {
          LoggerService.error('analytics service ~ analytics.logEvent', e);
        }
      ),
      window.fbq?.(eventName, eventParams, options),
      window.fbq?.('track', eventName),
    ]);
  };

  public createShareSessionEvent(sessionId: string, linkId: string) {
    return this.createShareEvent({name: EventName.ShareSessionEvent, sessionId, linkId});
  }

  public createShareProductEvent(sessionId: string, linkId: string) {
    return this.createShareEvent({name: EventName.ShareProductEvent, sessionId, linkId});
  }

  public createUseShareSessionEvent(sessionId: string, linkId: string) {
    return this.createShareEvent({name: EventName.UseShareSessionEvent, sessionId, linkId});
  }

  public createUseShareProductEvent(sessionId: string, linkId: string) {
    return this.createShareEvent({name: EventName.UseShareProductEvent, sessionId, linkId});
  }

  public createShareEvent({
    name,
    sessionId,
    linkId,
  }: {
    name: ShareEvents;
    sessionId: string;
    linkId: string;
  }) {
    const event = {
      name: name,
      userId: this.user.uid,
      sessionId,
      linkId,
    };
    return this.analytics_createSessionEvent(event);
  }

  public createPollEvent(
    sessionId: string | undefined,
    data: {
      name: PollEvents;
      pollId: string;
      pollState?: PollStatus;
      answerId?: string;
      itemName?: string;
      productId?: string;
    }
  ): void {
    if (sessionId) {
      const event = {
        sessionId,
        userId: this.user.uid,
        ...data,
      };

      if (isDevMode()) {
        console.log('poll analytics:', event);
      }

      this.analytics_createSessionEvent(event);
    }
  }

  public getSessionAnalyticsCounters(session: SessionDataDTO) {
    return this._getSessionAnalyticsCounters(session);
  }

  private _getSessionAnalyticsCounters(session: SessionDataDTO) {
    return this._getCounterFromPath(`sessions/${session.id}/analytics/counters`).pipe(
      shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler})
    );
  }

  private _getCounterFromPath(path: string) {
    const counters = this.firestore
      .doc<DbSessionAnalyticsCountersDoc>(path)
      .valueChanges()
      .pipe(shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler}));
    return counters.pipe(map((counters) => counters ?? {}));
  }

  getSessionOrders(session: SessionDataDTO) {
    return this.sessionService.getSessionOrders(session);
  }

  public analytics_createSessionEvent<T extends SessionEventData>(data: T & {sessionId: string}) {
    const subject = new Subject<void>();

    const observable = this.angularFireFunctions
      .httpsCallable<T, void>('analytics_createSessionEvent')(data)
      .pipe(
        tap(() => {
          try {
            LoggerService.log('analytics service ~ analytics_createSessionEvent ~ created', data);
            this.logEvent(data.name, data);
          } catch (error) {
            LoggerService.error('analytics service ~ analytics_createSessionEvent ~ error', error);
          }
        }),
        retry({count: 3, delay: 300, resetOnSuccess: true}),
        shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler}),
        subscribeOn(animationFrameScheduler)
      );

    observable.subscribe(subject);

    return subject;
  }
}
