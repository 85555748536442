import {Observable, retry} from 'rxjs';
import {autoLog} from './auto-log.decorator';

export function tapLog<T>(name?: string) {
  class logger {
    @autoLog(name)
    public static subscribe<T>(observer: Observable<T>) {
      return observer;
    }
  }
  return (source: Observable<T>): Observable<T> =>
    new Observable((observer) => {
      const subscription = logger.subscribe(source).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });

      return () => subscription.unsubscribe();
    });
}
export function tapLogWithRetry<T>(name?: string) {
  return (source: Observable<T>): Observable<T> =>
    tapLog<T>(name)(source.pipe(retry({count: 3, delay: 300, resetOnSuccess: true})));
}
