import type {default as firebase} from 'firebase';
import {v5} from 'uuid';
import {DbProductImageModel, DbProductModel, DbProductVariantModel} from '../db-models/product';
import {
  DottedStringPaths,
  MappedKeys,
  RequiredKeyPath,
  merge,
  objectToPathArrayWithValue,
  setAtLocation,
} from './object-helpers';
type Timestamp = firebase.firestore.Timestamp;

export type Transformer<
  From extends Record<string | number, any>,
  To extends Record<string | number, any>
> = {
  [key in keyof To]: DottedStringPaths<From>;
};

// let a: DottedStringPaths<DbProductVariantModel> = 'optionValues';

export function transformObject<
  From extends Record<string | number, any>,
  To extends Record<string | number, any>
>(inputObject: From, transformer: Transformer<From, To>): To {
  const transformedObject: Record<string, any> = {};
  const inputObjectKeyValuePairs = objectToPathArrayWithValue(inputObject);

  for (const path in transformer) {
    const key = transformer[path as keyof typeof transformer];
    if (!key) continue;
    const value = inputObjectKeyValuePairs.find((pair) => pair[0] === key)?.[1];
    if (!value) {
      console.log(`Value for key ${key} not found`, inputObject);
      continue;
    }
    try {
      const val = JSON.parse(value);

      setAtLocation(transformedObject, path as string, val);

      continue;
    } catch (error) {
      // do nothing
    }
    setAtLocation(transformedObject, path as string, value);
  }
  return transformedObject as To;
}

type RequiredFieldsOfProduct = RequiredKeyPath<DbProductModel, 'name'> &
  RequiredKeyPath<DbProductModel, 'price'> &
  RequiredKeyPath<DbProductModel, 'imageUrls'> &
  RequiredKeyPath<DbProductModel, 'externalUrl'> &
  Partial<MappedKeys<Omit<DbProductModel, 'name' | 'price' | 'imageUrls'>>>;

type RequiredFieldsOfVariant = RequiredKeyPath<DbProductVariantModel, 'price'> &
  RequiredKeyPath<DbProductVariantModel, 'optionValues'> &
  RequiredKeyPath<DbProductVariantModel, 'imageUrls'> &
  RequiredKeyPath<DbProductModel, 'externalUrl'> &
  Partial<MappedKeys<Omit<DbProductVariantModel, 'price' | 'optionValues' | 'imageUrls'>>>;
type InnerHTML = string;
type PlatformProduct = Record<string, any>;
export type ConvertProductsDto<T extends PlatformProduct> = {
  productRows: T[];
  storeId: string;
  productTransformer: Transformer<T, RequiredFieldsOfProduct>;
  variantTransformer: Transformer<T, RequiredFieldsOfVariant>;
  externalProductIdFn: (product: T) => string;
  externalVariantIdFn: (product: T) => string;
  productDescriptionFn?: (variantPage: InnerHTML) => string;
  productExtraDataFn?: (variant: T) => Promise<null | InnerHTML>;
  postTransformOfProductFn?: (product: DbProductModel) => Promise<DbProductModel> | DbProductModel;
  postTransformOfVariantFn?: (
    variant: DbProductVariantModel
  ) => Promise<DbProductVariantModel> | DbProductVariantModel;
};
/**
 * After getting the products we are doing this steps:
 * 1. convert the products to terrific format
 * 2. get product variants
 * 3. calc product options
 * 4. calc product and variant images
 * 5. add shipping options of the store to each product
 * 6. add shipping options of the store to each variant
 * 7. add createDate and lastUpdateDate to each product
 * 8. add storeId to each product and variant
 * 9. add orderIndex to each variant
 * 10. calc if variant is available via external url is returning 200-to 400 status code // ok / redirect
 * 11. get variant description from external url data
 *
 * @param converter dto
 * @returns terrific products and variants
 */
export async function convertProductsRowsToTerrificFormat<T extends PlatformProduct>({
  productRows,
  variantTransformer,
  storeId,
  productTransformer,
  externalProductIdFn,
  externalVariantIdFn,
  productDescriptionFn,
  productExtraDataFn,
  postTransformOfProductFn = (p) => p,
  postTransformOfVariantFn = (v) => v,
}: ConvertProductsDto<T>) {
  //create terrific products and variants from the platform products
  const {terrificProducts, terrificVariants} = await extractRawDataFromLines<T>(
    productRows,
    productTransformer,
    variantTransformer,
    externalProductIdFn,
    externalVariantIdFn,
    storeId,
    productDescriptionFn,
    productExtraDataFn
  );

  await Promise.all(
    [...terrificProducts].map(async (terrificProduct) => {
      terrificProduct.storeId = storeId;
      terrificProduct.createDate = new Date() as unknown as Timestamp;
      terrificProduct.lastUpdateDate = new Date() as unknown as Timestamp;
      terrificProduct.isDeleted = false;
      // terrificProduct.itemGettingSyncedFromExternalSource = true;
      terrificProduct.lastExternalUpdateDate = new Date() as unknown as Timestamp;
      terrificProduct.integrationDocId = 'csv file';
      terrificProduct.integrationType = 'csv';
      terrificProduct.shippingMethodIds = [];
      const terrificVariantsOfProduct = [...terrificVariants.values()].filter(
        (terrificVariant) => terrificVariant.productId === terrificProduct.id
      );
      if (terrificVariantsOfProduct.length === 0) {
        console.warn(
          'No variants found for product',
          terrificProduct.id,
          terrificProduct.externalId
        );
        return;
      }
      addStoreIdToVariants(terrificVariantsOfProduct, storeId);
      calcProductOptions(terrificProduct, terrificVariantsOfProduct);
      calcProductImages(terrificProduct, terrificVariantsOfProduct, storeId);
      for (let i = 0; i < terrificVariantsOfProduct.length; i++) {
        const variant = terrificVariantsOfProduct[i];
        variant.orderIndex = i;
      }
    })
  );

  return {
    terrificProducts: await Promise.all(terrificProducts.map(postTransformOfProductFn)),
    terrificVariants: await Promise.all(terrificVariants.map(postTransformOfVariantFn)),
  };
}

async function extractRawDataFromLines<T extends PlatformProduct>(
  productRows: T[],
  productTransformer: Transformer<T, RequiredFieldsOfProduct>,
  variantTransformer: Transformer<T, RequiredFieldsOfVariant>,
  externalProductIdFn: (product: T) => string,
  externalVariantIdFn: (product: T) => string,
  storeId: string,
  productDescriptionFn?: (variantPage: InnerHTML) => string,
  productExtraDataFn?: (variant: T) => Promise<null | InnerHTML>
) {
  const terrificProducts = new Map<string, DbProductModel>();
  const terrificVariants = new Map<string, DbProductVariantModel>();

  await Promise.all([
    productRows.forEach(async (platformProductRow) => {
      const terrificProduct = transformObject(
        platformProductRow,
        productTransformer
      ) as Partial<DbProductModel>;

      const terrificVariant = transformObject(
        platformProductRow,
        variantTransformer
      ) as Partial<DbProductVariantModel>;

      try {
        if (productExtraDataFn) {
          const extraData = await productExtraDataFn(platformProductRow);
          if (extraData) {
            terrificVariant.shortDescription =
              terrificProduct.shortDescription =
              terrificProduct.description =
                productDescriptionFn ? productDescriptionFn(extraData) : ' ';
          }
        }
      } catch (error) {}

      const productExternalId = String(externalProductIdFn(platformProductRow));
      const variantExternalId = String(externalVariantIdFn(platformProductRow));

      if (!productExternalId || !variantExternalId) {
        console.log('No external id found for product or variant', platformProductRow);
        return;
      }
      terrificProduct.id = getUuidFromStoreId(productExternalId, storeId);
      terrificProduct.externalId = productExternalId;

      terrificVariant.productId = terrificProduct.id;
      terrificVariant.id = getUuidFromStoreId(variantExternalId, storeId);
      terrificVariant.externalId = variantExternalId;

      const existingProduct = terrificProducts.get(productExternalId);
      const mergedProduct = merge(existingProduct ?? new DbProductModel(), terrificProduct);

      const existingVariant = terrificVariants.get(variantExternalId);
      const mergedVariant = merge(existingVariant ?? new DbProductVariantModel(), terrificVariant);

      if (mergedProduct) terrificProducts.set(productExternalId, mergedProduct as DbProductModel);
      if (mergedVariant)
        terrificVariants.set(variantExternalId, mergedVariant as DbProductVariantModel);
    }),
  ]);
  return {
    terrificProducts: [...terrificProducts.values()],
    terrificVariants: [...terrificVariants.values()],
  };
}

export function getUuidFromStoreId(str: string, storeId: string): string {
  const v5UuidRepresentationOfStoreId = v5(storeId, v5.DNS);
  const resultUuid = v5(str, v5UuidRepresentationOfStoreId);
  return resultUuid;
}

export function getUuidFromProductId(str: string, productId: string): string {
  const v5UuidRepresentationOfProductId = v5(productId, v5.DNS);
  const resultUuid = v5(str, v5UuidRepresentationOfProductId);
  return resultUuid;
}

function calcProductOptions(
  product: DbProductModel,
  terrificVariantsOfProduct: DbProductVariantModel[]
) {
  for (const variant of terrificVariantsOfProduct) {
    if (!product.options) product.options = [];
    for (const option in variant.optionValues) {
      const optionValues = product.options.find((o) => o.option === option);
      if (!optionValues) {
        product.options.push({option, values: [variant.optionValues[option]]});
      } else {
        if (!optionValues.values.includes(variant.optionValues[option])) {
          optionValues.values.push(variant.optionValues[option]);
        }
      }
    }
  }
}

function calcProductImages(
  product: DbProductModel,
  terrificVariantsOfProduct: DbProductVariantModel[],
  storeId: string
) {
  if (!product.images) product.images = [];
  const images = product.imageUrls ?? [];
  product.imageUrls = [...new Set(images)];
  product.imageUrls.forEach((imageUrl) => {
    product.images.push(
      imagesUrlsToProductImageModel(imageUrl, storeId) as unknown as DbProductImageModel
    );
  });
  for (const variant of terrificVariantsOfProduct) {
    variant.imageIds = [];
    for (const image in variant.imageUrls) {
      const imageUrls = product.images.find((o) => o.src === variant.imageUrls[image]);
      if (!imageUrls) {
        product.images.push(
          imagesUrlsToProductImageModel(
            variant.imageUrls[image],
            storeId
          ) as unknown as DbProductImageModel
        );
        product.imageUrls.push(variant.imageUrls[image]);
        variant.imageIds.push(getUuidFromStoreId(variant.imageUrls[image], storeId));
      } else {
        variant.imageIds.push(imageUrls.id);
      }
    }
  }
}

function imagesUrlsToProductImageModel(src: string, storeId: string) {
  const id = getUuidFromStoreId(src, storeId);
  const now = new Date();
  return {
    src,
    createDate: now,
    lastUpdateDate: now,
    id,
    externalId: src.split('/').pop()?.split('.').shift() ?? null,
  };
}
function addStoreIdToVariants(terrificVariantsOfProduct: DbProductVariantModel[], storeId: string) {
  for (const variant of terrificVariantsOfProduct) {
    variant.storeId = storeId;
  }
}
