import {Component, inject, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
  public modal = inject(NgbActiveModal);
  @Input() title: string;
  @Input() description: string;
  @Input() yesButtonText: string;
  @Input() noButtonText: string;

  public yes() {
    this.modal.close(true);
  }

  public no() {
    this.modal.close(false);
  }
}
