import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import * as Sentry from '@sentry/browser';
import {LogLevel} from './logger-level.enum';
import stringify from 'fast-safe-stringify';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {animationFrameScheduler} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private afs?: AngularFireFunctions) {}
  logs?: any[] = [];
  static LOGS_TO_KEEP = 500;

  /**
   *
   * @param level the log level
   * @param message the log message, please use template literals to avoid string concatenation
   * the message should be a string, if you want to log an object, please use the metadata parameter
   * do use a constant log name, so that we can easily search for it in the logs
   * avoid using runtime names like ${this.id} or ${this.name}
   * @param metadata the log metadata, this should be an object
   * @returns void
   */
  logMessage(level: LogLevel, message: string, metadata: Object = {}) {
    animationFrameScheduler.schedule(() => {
      this.logs!.push({level, message, metadata});
      if (this.logs!.length > LogService.LOGS_TO_KEEP) {
        this.logs = this.logs!.slice(-LogService.LOGS_TO_KEEP);
      }
      if (!environment.production || level !== LogLevel.DEBUG) {
        console[level](message, {...metadata});
      }

      if (level !== LogLevel.ERROR || environment.useEmulators) return;

      const log = JSON.parse(
        // remove circular references and other unserializable properties
        safeStringify({
          level,
          message,
          metadata: metadata,
          timestamp: new Date().toISOString(),
          localTime: new Date().toLocaleString(),
          logs: this.logs,
        })
      );

      this.afs
        ?.httpsCallable('logsLogger')(log)
        .subscribe({
          error: (err) => Sentry.captureException(err),
        });
    }, 100);
  }
}

function safeStringify(obj: any): string {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return stringify(obj);
  }
}
