<!-- Close modal button -->
<button (click)="modal.dismiss()" class="btn-close-modal" type="button">
  <img alt="Close" src="assets/images/icons/close-modal-icon.svg" />
</button>
<!-- Close modal button mobile-->
<button (click)="modal.dismiss()" class="btn-close-modal-mobile">
  <img alt="Close" src="assets/images/icons/close-modal-icon-mobile.svg" />
</button>

<!-- Title -->
<h2 class="modal-title">{{ title }}</h2>
<p class="text-center mb-3">{{ description }}</p>
<div class="d-flex flex-column-reverse flex-xl-row justify-content-around">
  <app-button
    [btnClasses]="'mr-xl-2'"
    [variant]="'light'"
    [size]="'small'"
    [fullWidth]="true"
    (clickEmitter)="no()"
    [customStyle]="{'background': '#f3f3f3', 'color': 'var(--primary-store)'}"
  >
    {{ noButtonText }}
  </app-button>
  <app-button
    [btnClasses]="'mb-2 mb-xl-0 ml-xl-2'"
    (clickEmitter)="yes()"
    [size]="'small'"
    [fullWidth]="true"
  >
    {{ yesButtonText }}
  </app-button>
</div>
