import {environment} from 'src/environments/environment';
/**
 * @deprecated The method should not be used use the LogService instead
 */
export const LoggerService = new Proxy<Console>(console, {
  get(target, prop: keyof Console, receiver) {
    return (...args: any[]) => {
      if (!environment.production) {
        Reflect.get(target, prop as PropertyKey, receiver)(...args);
      } else if (prop !== 'debug') {
        const consoleProp = console[prop] as Function;
        consoleProp.call(console, ...args);
      }
    };
  },
  set(t, p, n, r) {
    return Reflect.set(t, p, n, r);
  },
});
