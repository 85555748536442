/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {HttpErrorResponse} from '@angular/common/http';
import {Component, inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import firebase from 'firebase/compat';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe-decorator';
import {ToastrService} from 'ngx-toastr';
import type {PhoneResult} from 'phone';
import {combineLatest, firstValueFrom, from, Subject, timer} from 'rxjs';
import {finalize, first, map, skip, switchMap, takeUntil} from 'rxjs/operators';
import {LanguageService} from 'src/app/language.service';
import {autoLog} from 'src/app/logger/auto-log.decorator';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {LoggerService} from 'src/app/services/logger.service';
import {MissingData} from 'src/app/services/users.utils';
import {SplitInputComponent} from '../../components/split-input/split-input.component';
import {CompareHelpers} from '../../helpers/compare-helpers';
import FormHelpers from '../../helpers/form-helpers';
import regex from '../../helpers/regex';
import {ActiveStoreModel, CountriesCodes as CountryISOData} from '../../interfaces/store-models';
import {StoresService} from '../../services/stores.service';
import {PhoneValidationResult, UsersService} from '../../services/users.service';
import UserCredential = firebase.auth.UserCredential;
import AuthCredential = firebase.auth.AuthCredential;
import ConfirmationResult = firebase.auth.ConfirmationResult;

export enum AuthModalState {
  Login = 1,
  Register = 2,
  PhoneNumber = 3,
  CodeVerification = 4,
  ForgotPassword = 5,
  ResetPassword = 6,
  LinkAccounts = 7,
  AskForDetails = 8,
}

export enum SignUpMethod {
  None = 0,
  Google = 1,
  Facebook = 2,
  Sms = 3,
  Email = 4,
}

// Patterns
const PAT_NAME = regex.NAME_REGEX;
const PAT_EMAIL = regex.EMAIL_REGEX;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
})
export class LoginModalComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public modal = inject(NgbActiveModal);
  public usersService = inject(UsersService);
  public storeService = inject(StoresService);
  public formBuilder = inject(UntypedFormBuilder);
  public toastr = inject(ToastrService);
  public translateService = inject(TranslateService);
  public analytics = inject(AnalyticsService);
  public router = inject(Router);
  public languageService = inject(LanguageService);

  AuthModalState = AuthModalState;
  SignUpMethod = SignUpMethod;

  @Input() startState: AuthModalState;
  @Input()
  private _showFields?: MissingData | undefined;
  destroyed$ = new Subject<void>();

  public get showFields(): MissingData | undefined {
    return this._showFields;
  }

  public set showFields(value: MissingData | undefined) {
    this._showFields = value;
    this.setMissingDataFormFields();
  }

  @Input() resetCode: string | null;
  @Input() user: firebase.User | null;
  @Input() openedFromRedirect: boolean;

  @ViewChild(SplitInputComponent) codeInput: SplitInputComponent;

  public state: AuthModalState;
  public returnState: AuthModalState;
  public activeTab = 1;
  public logoUrl = '';

  public activeStore: ActiveStoreModel | null = this.storeService.getActiveStoreSync();

  // Properties for signUp with email and password
  public signUpMethod: SignUpMethod = SignUpMethod.None;
  private phoneNumberValue = '';
  public codeValue = '';
  private email = '';
  private password = '';
  private fullName = '';

  // Link accounts.
  public originalAccounts: string[];
  public accountToLinkCredentials: AuthCredential;

  // Handle countries flags and phone codes input
  countryCodes$ = combineLatest({
    codes: from(import('../../country-codes.json')),
    location: this.usersService.getGeoLocationData(),
  }).pipe(
    map(({codes, location}) => {
      console.log({codes, location});
      codes = Array.from(codes).sort((a, b) =>
        CompareHelpers.compareStrings(a.name, b.name, false)
      );
      this.countryCodes = codes;

      const geoData = JSON.stringify(location);
      // @ts-expect-error this line does magic
      const userCountryCode = JSON.parse(geoData).country_code ?? 'US';
      // @ts-expect-error this line does magic
      this.userNationality = JSON.parse(geoData).country_name;
      const defaultCountryIndex = this.countryCodes.findIndex(
        (country) => country.isoCode === userCountryCode
      );
      this.countryCodes.splice(0, 0, this.countryCodes.splice(defaultCountryIndex, 1)[0]);
      this.selectedCountry = this.countryCodes[0];

      return codes;
    })
  );
  countryCodes: CountryISOData[] = [];

  public selectedCountry: CountryISOData | undefined;
  private phone: undefined | ((...args: any[]) => PhoneResult);

  @AutoUnsubscribe()
  private phoneSubscription = combineLatest([from(import('phone')), this.countryCodes$]).subscribe({
    next: ([{phone}]) => {
      this.phone = phone;
    },
  });

  //nationality
  public userNationality: string;

  // Sms verification
  private phoneAuthConfirmation: ConfirmationResult | PhoneValidationResult;
  public resetRecaptcha: boolean;

  // Forms

  public phoneForm: UntypedFormGroup;
  public readonly signInForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(PAT_EMAIL)]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });
  public readonly signUpForm = this.formBuilder.group(
    {
      fullName: ['', [Validators.required, Validators.pattern(PAT_NAME)]],
      email: ['', [Validators.required, Validators.pattern(PAT_EMAIL)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    },
    {
      validator: (group: UntypedFormGroup) => this.checkPasswords(group),
    }
  );
  public readonly forgotPasswordForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(PAT_EMAIL)]],
  });
  public readonly resetPasswordForm: UntypedFormGroup = this.formBuilder.group(
    {
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    },
    {
      validator: (group: UntypedFormGroup) => this.checkPasswords(group),
    }
  );
  public readonly linkAccountsForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(PAT_EMAIL)]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });
  public fillMissingDataForm: UntypedFormGroup;

  // Flag to check if form submitted by user to handle error messages
  // public isFormSubmitted: boolean = false;
  public showUserNotFoundMessage = false;
  public showEmailInvalidMessage = false;
  public showEmailAlreadyInUseMessage = false;
  public showPasswordInvalidMessage = false;
  public showCodeInvalidMessage: string | null = null;
  public showPhoneInvalidMessage: string | null = null;

  // Flad to toggle show and confirm password input view
  public showPassword = false;
  public showConfirmPassword = false;

  // Flag to when form is disable while server work
  public isSendingToServer = false;
  isFormSubmitted: boolean;

  public agreedPolicy = false;

  ngOnInit() {
    LoggerService.info('LoginModalComponent: ngOnInit');

    this.phoneForm = this.formBuilder.group({
      phonePrefix: ['', [Validators.required]],
      phoneNumber: [
        '',
        {
          updateOn: 'blur',
          validators: [(Validators.required, this.phoneNumberValidator())],
          asyncValidators: [],
        },
        {},
      ],
    });

    if (this.startState === AuthModalState.Register) this.activeTab = 2;
    else this.activeTab = 1;

    this.state = this.startState;

    if (this.activeStore) this.logoUrl = this.activeStore.logoUrl;

    this.setMissingDataFormFields();
  }

  @autoLog('login-modal ~ ngOnDestroy')
  private setMissingDataFormFields() {
    if (!this.showFields) return;
    this.fillMissingDataForm = this.formBuilder.group({
      ...(this.showFields.displayName && {
        fullName: ['', [Validators.required, Validators.pattern(PAT_NAME)]],
      }),
      ...(this.showFields.email && {
        email: ['', [Validators.required, Validators.pattern(PAT_EMAIL)]],
      }),
      ...(this.showFields.phone && {
        phonePrefix: ['', [Validators.required]],
        phoneNumber: [
          '',
          {
            updateOn: 'blur',
            validators: [(Validators.required, this.phoneNumberValidator())],
            asyncValidators: [],
          },
          {},
        ],
      }),
    });
  }

  /**
   * Validate if confrim password and password are equal
   *
   * @param group
   */
  private checkPasswords(group: UntypedFormGroup): ValidationErrors | null {
    let result = null;

    const pass = group.controls.password.value;
    const confirmPassControl = group.controls.confirmPassword;
    if (pass !== confirmPassControl.value) {
      result = {notSame: true};
      confirmPassControl.setErrors({incorrect: true});
    }
    return result;
  }

  public async signupWithGoogle() {
    this.analytics.logEvent('sign_up', {
      path: this.router.url,
      method: 'Google',
    });
    await this.usersService
      .googleSignUp()
      .then(async (credential) => {
        this.user = credential.user ? credential.user : null;
        if (this.user) {
          if (this.user.phoneNumber) {
            await this.closeModalAfterSuccessfulUserRefresh();
          } else {
            this.returnState = AuthModalState.Register;
            this.state = AuthModalState.PhoneNumber;
          }
        }
      })
      .catch(async (error) => {
        LoggerService.error(error);
        // The provider's account email, can be used in case of
        // auth/account-exists-with-different-credential to fetch the providers

        // If it's different login method error, suggest linking. Otherwise, show an error message.
        if (!(await this.checkIfDifferentLoginMethod(error))) {
          // The provider's credential:
          this.toastr.error(error.message);
        }
      });
  }

  public signupWithFacebook() {
    this.analytics.logEvent('sign_up', {
      path: this.router.url,
      method: 'Facebook',
    });
    this.usersService
      .facebookSignUp()
      .then(async (credential) => {
        this.user = credential.user ? credential.user : null;
        if (this.user) {
          if (this.user.phoneNumber) {
            await this.closeModalAfterSuccessfulUserRefresh();
          } else {
            this.returnState = AuthModalState.Register;
            this.state = AuthModalState.PhoneNumber;
          }
        }
      })
      .catch(async (error) => {
        LoggerService.error(error.message);
        // The provider's account email, can be used in case of
        // auth/account-exists-with-different-credential to fetch the providers
        // linked to the email

        // If it's different login method error, suggest linking. Otherwise, show an error message.
        if (!(await this.checkIfDifferentLoginMethod(error))) {
          // The provider's credential:
          this.toastr.error(error.message);
        }
      });
  }

  public signupWithSms() {
    this.signUpMethod = SignUpMethod.Sms;
    this.returnState = AuthModalState.Register;
    this.state = AuthModalState.PhoneNumber;
  }

  public signUpWithEmailAndPassword() {
    this.analytics.logEvent('sign_up', {
      path: this.router.url,
      method: 'signup_EmailAndPassword',
      countryCodes: this.countryCodes$,
    });
    // this.isFormSubmitted = true;
    this.showPasswordInvalidMessage = false;
    this.showEmailInvalidMessage = false;
    this.showEmailAlreadyInUseMessage = false;
    if (!this.signUpForm.valid) {
      FormHelpers.markFormGroupInvalidAndFocus(this.signUpForm);
    } else {
      this.email = this.signUpForm.value.email;
      this.password = this.signUpForm.value.password;
      this.fullName = this.signUpForm.value.fullName;
      this.usersService
        .isEmailRegistered(this.email)
        .then((providers) => {
          if (providers.length > 0) {
            this.showEmailAlreadyInUseMessage = true;
          } else {
            // this.isFormSubmitted = false;
            this.signUpMethod = SignUpMethod.Email;
            this.returnState = AuthModalState.Register;
            this.state = AuthModalState.PhoneNumber;
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          if (errorCode === 'auth/invalid-email') this.showEmailInvalidMessage = true;
        });
    }
  }

  /**
   * Send phone number to server for user fget validation code by sms
   */
  public async sendPhoneToServer(): Promise<void> {
    this.showPhoneInvalidMessage = null;
    if (!this.phoneForm.valid && !this.fillMissingDataForm) {
      FormHelpers.markFormGroupInvalidAndFocus(this.phoneForm);
    } else {
      this.isSendingToServer = true;
      if (false /*this.signUpMethod !== SignUpMethod.Sms || !!this.fillMissingDataForm*/) {
        this.usersService
          .sendPhoneValidation(this.phoneNumberValue)
          .pipe(
            finalize(() => {
              this.isSendingToServer = false;
            })
          )
          .subscribe({
            next: () => {
              // Todo what happen if phone is not valid
              this.state = AuthModalState.CodeVerification;
            },
            error: (error) => {
              const code = error.code;
              LoggerService.error(error);
              this.showPhoneInvalidMessage = error.message;
              if (code === 'invalid-argument') {
                // this.showPhoneInvalidMessage = true;
              }
            },
          });
      } else {
        // In case user press back and switched to another sign in method (recaptcha already exists)
        this.resetRecaptcha = true;
        setTimeout(async (): Promise<void> => {
          this.resetRecaptcha = false;
          const phonePromise: Promise<PhoneValidationResult> | Promise<ConfirmationResult> =
            this.signUpMethod !== SignUpMethod.Sms || !!this.fillMissingDataForm
              ? this.usersService.verifyPhoneNumber(this.phoneNumberValue)
              : this.usersService.signInWithPhone(this.phoneNumberValue);
          phonePromise
            .then((result) => {
              this.phoneAuthConfirmation = result;
              this.state = AuthModalState.CodeVerification;
              this.isSendingToServer = false;
            })
            .catch((error: 'reCAPTCHA expired' | HttpErrorResponse) => {
              this.toastr.error(error === 'reCAPTCHA expired' ? error : error.message);
            });
        });
      }
    }
  }

  /**
   * Get the code user entered
   *
   * @param code
   */
  public getCodeFromInput(code: string) {
    this.codeValue = code;
  }

  /**
   * Submit code to check if phone-code match
   * If we signIn with email then validateCodeAndSaveUser get called
   */
  public submitCode() {
    if (!this.codeValue || this.codeValue.length < 6) {
    } else {
      this.isSendingToServer = true;
      this.showCodeInvalidMessage = null;

      // Validate code (TODO: Not the best idea to check captcha on front)
      this.phoneAuthConfirmation
        .confirm(this.codeValue)
        .then(async (result) => {
          if (this.signUpMethod === SignUpMethod.Email) {
            this./*validateCodeAnd*/ saveUserFromEmail();
          } else if (this.signUpMethod === SignUpMethod.Sms) {
            // User signed in successfully.
            this.isSendingToServer = false;
            const user = (result as UserCredential).user;
            await this.usersService.reloadUser();
            await firstValueFrom(this.usersService.connectedUser.pipe(skip(1)));

            // Check if the user already entered his full name

            this.showFields = {
              displayName: !user?.displayName,
              email: !user?.email,
              phone: !user?.phoneNumber,
            };
            const isMissingData = Object.values(this.showFields).some((value) => value);

            if (!isMissingData) {
              this.modal.close();
              return;
            }

            this.setMissingDataFormFields();
            this.state = AuthModalState.AskForDetails;
          } else {
            this.sendCode();
          }
        })
        .catch((error) => {
          this.isSendingToServer = false;
          if (error.message) {
            this.showCodeInvalidMessage = error.message;
            return;
          }
          this.toastr.error(error);
          LoggerService.error(JSON.stringify(error), error);
        });
    }
    this.analytics.logEvent('select_content', {
      path: this.router.url,
      content_type: 'submit_code_to_check_if_phone_code_match',
    });
  }

  /**
   * Send validation code to server
   *
   * @param code
   */
  public sendCode() {
    let language: string | null = this.languageService.getSavedLanguage();
    language = language ? language : 'en';
    this.isSendingToServer = true;
    this.analytics.logEvent('select_content', {
      path: this.router.url,
      content_type: 'send_code_to_connected',
      language: language,
    });
    this.usersService
      .sendCode(
        this.phoneNumberValue,
        this.codeValue,
        this.activeStore?.id,
        language,
        this.userNationality
      )
      .pipe(
        finalize(() => {
          this.isSendingToServer = false;
        })
      )
      .pipe(
        switchMap(async () => {
          await this.closeModalAfterSuccessfulUserRefresh();
          this.toastr.success('Terrific! Your account has been created successfully.');
          this.codeInput.clearInput();
        })
      )
      .subscribe({
        error: (error) => {
          const errorCode: string =
            error instanceof Error
              ? error.message
              : typeof error === 'string'
              ? error
              : JSON.stringify(error);

          this.showCodeInvalidMessage = errorCode;
        },
      });
  }

  /**
   * Get call when user sign up with email
   */
  public /*validateCodeAnd*/ saveUserFromEmail() {
    this.analytics.logEvent('sign_up', {
      path: this.router.url,
      method: 'sign up with email',
    });
    let language: string | null = this.languageService.getSavedLanguage();
    language = language ? language : 'en';
    this.usersService
      ./*validateCodeAnd*/ saveUser(
        this.email,
        this.password,
        this.fullName,
        this.phoneNumberValue,
        // this.codeValue,
        this.activeStore?.id,
        language,
        this.userNationality
      )
      .pipe(
        switchMap(async () => await this.usersService.signInWithEmail(this.email, this.password)),
        switchMap(async () => {
          await this.closeModalAfterSuccessfulUserRefresh();
          this.toastr.success('Terrific! Your account has been created successfully.');
          this.codeInput.clearInput();
        }),
        finalize(() => {
          this.isSendingToServer = false;
        })
      )
      .subscribe({
        error: (error) => {
          const message = error.message as string;
          LoggerService.error(error);
          if (error.message) {
            this.showCodeInvalidMessage = error.message;
            return;
          }
          this.toastr.error(
            message +
              ' Please try signing in and if the problem persists please contact support - info@terrificlive.com'
          );
        },
      });
  }

  /**
   * Toggle the password or confirmPassword view
   *
   * @param buttonName
   */
  public toggleShowPassword(buttonName: string) {
    if (buttonName === 'password') this.showPassword = !this.showPassword;
    else this.showConfirmPassword = !this.showConfirmPassword;
  }

  /**
   * Sign in with email and password
   */
  public signInWithEmail() {
    this.analytics.logEvent('signIn_Email', {
      path: this.router.url,
      method: 'signIn_Email',
    });
    // this.isFormSubmitted = true;
    this.showPasswordInvalidMessage = false;
    this.showEmailInvalidMessage = false;
    if (!this.signInForm.valid) {
      FormHelpers.markFormGroupInvalidAndFocus(this.signUpForm);
    } else {
      this.isSendingToServer = true;
      this.email = this.signInForm.value.email;
      this.password = this.signInForm.value.password;
      this.signInForm.disable();
      this.usersService
        .signInWithEmail(this.email, this.password)
        .then(async (credential) => {
          // this.isFormSubmitted = false;
          this.user = credential.user;
          if (this.user?.phoneNumber) {
            await this.closeModalAfterSuccessfulUserRefresh();
          } else {
            this.returnState = AuthModalState.Login;
            this.state = AuthModalState.PhoneNumber;
            this.changeModalTab();
          }
        })
        .catch(async (error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === 'auth/wrong-password') {
            // Check if it's a method we do not have.
            await this.usersService.fetchSignInMethodForEmail(this.email).then((x) => {
              // Check if a password method exists.
              if (x.length > 0 && x.includes('password')) {
                this.translateService
                  .get('ERROR.SIGN_IN_INVALID_PASSWORD')
                  .subscribe((text: string) => {
                    this.toastr.error(text);
                  });

                this.showPasswordInvalidMessage = true;
              } else {
                this.translateService.get('ERROR.NO_PASSWORD_OPTION').subscribe((text: string) => {
                  this.toastr.error(text);
                });
              }
            });
          }

          if (errorCode === 'auth/invalid-email') this.showEmailInvalidMessage = true;

          if (errorCode === 'auth/user-not-found') {
            this.showUserNotFoundMessage = true;
            LoggerService.error(error.message);

            this.translateService.get('ERROR.USER_NOT_FOUND').subscribe((text: string) => {
              this.toastr.error(text);
            });
          }
          if (errorCode === 'auth/user-disabled') this.toastr.error(errorMessage);
        })
        .finally(() => {
          this.signInForm.enable();
          this.isSendingToServer = false;
        });
    }
  }

  private async closeModalAfterSuccessfulUserRefresh() {
    await this.usersService.reloadUser();
    this.usersService.afUser$.pipe(first()).subscribe((user) => {
      LoggerService.log('User refreshed', user);
      this.user = user;
      if (user?.displayName && user.email && user.phoneNumber) this.modal.close();

      if (!this.showFields) {
        this.showFields = {
          displayName: !user?.displayName,
          email: !user?.email,
          phone: !user?.phoneNumber,
        };
        this.state = AuthModalState.AskForDetails;
      }

      this.usersService.connectedUser.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
        LoggerService.debug('connectedUser', user, this.showFields);
        if (!this.showFields) return this.modal.close();
        if (
          !(this.showFields.displayName && !user?.displayName) &&
          !(this.showFields.email && !user?.email) &&
          !(this.showFields.phone && !user?.phone)
        )
          this.modal.close();
      });
    });
  }

  /**
   * Sign in with Google or Facebook
   *
   * @param provider
   */
  public signInWithProvider(provider: string) {
    this.analytics.logEvent('signIn_Google_Facebook', {
      path: this.router.url,
      method: 'signIn_Google/Facebook',
    });
    this.signInForm.disable();
    this.isSendingToServer = true;
    this.usersService
      .signInWithProvider(provider)
      .then(async (credential) => {
        this.user = credential.user;
        if (this.user) {
          if (this.user.phoneNumber) {
            await this.closeModalAfterSuccessfulUserRefresh();
          } else {
            this.returnState = AuthModalState.Login;
            this.state = AuthModalState.PhoneNumber;
            this.changeModalTab();
          }
        }
      })
      .catch(async (error) => {
        LoggerService.error(error);

        // If it's different login method error, suggest linking. Otherwise, show an error message.
        if (!(await this.checkIfDifferentLoginMethod(error))) {
          // The provider's credential:
          this.toastr.error(error.message);
        }
      })
      .finally(() => {
        this.signInForm.enable();
        this.isSendingToServer = false;
      });
  }

  public signInWithSms() {
    this.signUpMethod = SignUpMethod.Sms;
    this.returnState = AuthModalState.Login;
    this.state = AuthModalState.PhoneNumber;
  }

  private async checkIfDifferentLoginMethod(error: any): Promise<boolean> {
    // Check error type.
    const differentLoginMethodError =
      error.code === 'auth/account-exists-with-different-credential';
    if (differentLoginMethodError) {
      await this.usersService.fetchSignInMethodForEmail(error.email).then((x) => {
        // Just double-check that we have at least one provider.
        if (x.length > 0) {
          // Save the credentials for the account linking.
          this.accountToLinkCredentials = error.credential;

          // Save the type of original accounts.
          this.originalAccounts = x;

          // Move to the next step.
          this.state = AuthModalState.LinkAccounts;
        }
      });
    }

    return differentLoginMethodError;
  }

  public async linkAccountsWithOriginalProvider(provider: string) {
    await this.usersService.signInWithProvider(provider).then(async () => {
      // Link the previous account.
      await this.usersService.linkWithExistingCredential(this.accountToLinkCredentials);
    });
  }

  public async linkAccountsWithEmailAndPassword() {
    await this.usersService
      .signInWithEmail(this.linkAccountsForm.value.email, this.linkAccountsForm.value.password)
      .then(async () => {
        // Link the previous account.
        await this.usersService.linkWithExistingCredential(this.accountToLinkCredentials);
      });
  }

  public changeModalTab() {
    if (this.activeTab === 1) this.activeTab = 2;
    else this.activeTab = 1;
  }

  public async reSendCode() {
    if (false && this.signUpMethod === SignUpMethod.Sms) {
      this.resetRecaptcha = true;
      timer(10)
        .pipe(
          switchMap(async () => {
            this.resetRecaptcha = false;
            await this.sendPhoneToServer();
          })
        )
        .subscribe();
    } else {
      await this.sendPhoneToServer();
    }
  }

  public async submitMissingDataForm() {
    if (!this.showFields) return;
    this.isSendingToServer = true;

    if (this.showFields.email) this.email = this.fillMissingDataForm.value.email;
    if (this.showFields.displayName) this.fullName = this.fillMissingDataForm.value.fullName;
    if (this.showFields.phone) {
      await this.sendPhoneToServer();
    }

    const userInfo$ = this.usersService.updateUserInfo(
      this.fullName,
      '',
      '',
      '',
      this.email,
      this.phoneNumberValue
    );

    userInfo$
      .pipe(
        switchMap(async () => {
          if (!this.showFields?.phone) await this.closeModalAfterSuccessfulUserRefresh();
        }),
        finalize(() => {
          this.isSendingToServer = false;
        })
      )
      .subscribe({
        error: (error) => {
          const errorCode = error.code;
          if (errorCode === 'auth/invalid-email') this.showEmailInvalidMessage = true;

          LoggerService.error(error);
        },
      });
  }

  public goToForgotPassword() {
    this.state = AuthModalState.ForgotPassword;
  }

  /**
   * Send forgot password to server
   */
  public sendForgotPassword() {
    this.showEmailInvalidMessage = false;
    if (!this.forgotPasswordForm.valid) {
      FormHelpers.markFormGroupInvalidAndFocus(this.forgotPasswordForm);
    } else {
      this.isSendingToServer = true;
      this.usersService
        .sendUserResetPassword(this.forgotPasswordForm.value.email, this.activeStore?.id)
        .pipe(
          finalize(() => {
            this.isSendingToServer = false;
          })
        )
        .subscribe({
          next: () => {
            this.translateService.get('LOGIN.SEND_RESET_SUCCESS').subscribe((text: string) => {
              this.toastr.success(text);
            });
          },
          error: (error) => {
            LoggerService.error(error);
            this.toastr.error(
              "It seems that this email hasn't been used before, please try with another one."
            );
          },
        });
    }
  }

  public toggleAgreedPolicy() {
    this.agreedPolicy = !this.agreedPolicy;
  }

  /**
   * Send forgot password to server
   */
  public sendResetPassword() {
    this.showEmailInvalidMessage = false;
    if (!this.resetPasswordForm.valid) {
      FormHelpers.markFormGroupInvalidAndFocus(this.resetPasswordForm);
    } else {
      this.isSendingToServer = true;
      this.usersService
        .completeResetPassword(this.resetCode!, this.resetPasswordForm.value.password)
        .pipe(
          finalize(() => {
            this.isSendingToServer = false;
          })
        )
        .subscribe({
          next: () => {
            this.toastr.success('Your password has been reset successfully');
            this.dismissModal();
          },
          error: (error) => {
            LoggerService.error(error);
            this.toastr.error('Failed to reset the password. Please try again later');
          },
        });
    }
  }

  public goToLogin() {
    this.state = AuthModalState.Login;
    this.activeTab = 1;
  }

  public dismissModal() {
    // this.isFormSubmitted = false;
    this.modal.dismiss();
  }

  public customSearchFn(term: string, item: any): boolean {
    term = term.toLocaleLowerCase();
    return (item.name.toLocaleLowerCase().includes(term) ||
      item.dialCode.toLocaleLowerCase().includes(term)) as boolean;
  }

  public removeErrorMessageSignUpForm(inputName: string) {
    this.signUpForm.controls[inputName].markAsUntouched();
  }

  public removeErrorMessageSignInForm(inputName: string) {
    this.signInForm.controls[inputName].markAsUntouched();
  }

  public removeErrorMessageFillMissingDataForm(inputName: string) {
    this.fillMissingDataForm.controls[inputName].markAsUntouched();
  }

  /**
   * Checks what action depend  if form was open from redirect window back from phoneForm
   */
  public backFromPhoneForm() {
    if (this.openedFromRedirect) this.modal.dismiss();
    else this.state = this.returnState;
  }

  phoneNumberValidator(): ValidatorFn {
    return (phoneInput: AbstractControl<string>) => {
      if (!this.phone) return null;
      const phonePrefixControl = phoneInput.root.get('phonePrefix');
      const selectedCountry = phonePrefixControl?.value as CountryISOData | undefined;

      const phoneNumberString1: string = `${selectedCountry?.dialCode ?? ''}${
        phoneInput.value ?? ''
      }`;
      const phoneNumberString2: string = `${phoneInput.value}`;
      for (const phoneNumberString of [phoneNumberString1, phoneNumberString2]) {
        const validationResult = this.phone(phoneNumberString, {
          country: phoneNumberString2.startsWith('+') ? undefined : selectedCountry?.isoCode,
          validateMobilePrefix: true,
        });

        if (!validationResult.isValid) {
          continue;
        }

        const preFix = validationResult.countryCode;
        const phoneNumber = validationResult.phoneNumber.replace(preFix, '');

        if (
          // no country selected yet
          !this.selectedCountry?.dialCode ||
          // country changed after the user entered a full number
          // including the country code on the phone input
          selectedCountry?.dialCode !== preFix ||
          // user entered a full number including the country code on the country input
          !phoneInput.value ||
          // user entered a full number including the country code on the phone input and its the same prefix as the country input
          phoneNumber !== phoneInput.value
        ) {
          this.selectedCountry =
            this.countryCodes.find((country) => country.isoCode === validationResult.countryIso2) ??
            this.countryCodes[0];
          phonePrefixControl?.setValue(this.selectedCountry);
          phoneInput.setValue(phoneNumber);
        }
        this.showPhoneInvalidMessage = null;

        this.phoneNumberValue = validationResult.phoneNumber;
        return null;
      }

      if (!selectedCountry) {
        return {
          e: 'Please select a country',
        };
      }

      return {
        e: phoneNumberString1 ?? '',
      };
    };
  }
}
