// staging and devs
import {environmentVars as localEnv} from './local';
import {generalVars} from './general';
export const environmentVars = {
  ...generalVars,
  allowImageExt: '.jpg,.png,.jpeg',
  maxUplodedImageSize: 5000001,
  production: false,
  stripePublishKey:
    'pk_test_51Iuiu6FXQqlOoXxhPrkRwyGEDJHHzGthVMV2suClO1a1W5rxCOliCMLafMJhYp8S7dfxNxgASlGWsXTSTMEqvFpu008LoVLHRt',
  useEmulators: false,
  featureFlags: {
    ...localEnv.featureFlags,
  },
  agora: {
    appId: '6bc227bdda1d47eda35749fe6a6605ec',
    //  * - 0: DEBUG. Output all API logs.
    //  * - 1: INFO. Output logs of the INFO, WARNING and ERROR level.
    //  * - 2: WARNING. Output logs of the WARNING and ERROR level.
    //  * - 3: ERROR. Output logs of the ERROR level.
    //  * - 4: NONE. Do not output any log.
    logLevel: 3,
  },
  shopifyAppUrl: 'https://partners.shopify.com/2615331/apps/8735621121/test',
  logLevel: 0,
  typesenseCollectionNames: {
    products: 'staging-products',
  },
};
