import {Injectable, Injector, inject} from '@angular/core';
import {hasNew} from '../../../../shared/utilities/type-guards';

type lib = any;

@Injectable({providedIn: 'root'})
export class LazyServiceProvider {
  private injector = inject(Injector);

  /**
   * This method returns a singleton instance of a service. If the service has a constructor (i.e., it's a class),
   * it will try to get the instance from Angular's injector. If the service is not registered in the injector,
   * or it's a simple value or function, it will create a new instance.
   *
   * @param {T} service - The service to get an instance of.
   * @returns {() => T extends new (...args: any) => any ? InstanceType<T> : T} - A function that returns the singleton instance of the service.
   */
  public get<T extends lib>(
    service: T
  ): () => T extends new (...args: any) => any ? InstanceType<T> : T {
    let instance: any = false;
    const injectFn = () => {
      if (!hasNew(service)) return service;

      try {
        return this.injector.get(service);
      } catch (e) {
        return new service();
      }
    };
    return () => (instance = instance || injectFn());
  }

  /**
   * This method is similar to the get method, but it's designed to work with services that are loaded asynchronously (e.g., using dynamic imports).
   * It takes a function that returns a promise of a service, waits for the service to be loaded, and then gets a singleton instance of the service.
   *
   * @param {() => Promise<T>} service - A function that returns a promise of a service.
   * @returns {Promise<() => T extends new (...args: any) => any ? InstanceType<T> : T>} - A promise that resolves to a function that returns the singleton instance of the service.
   */
  public async getAsync<T extends lib>(service: () => Promise<T>) {
    const importedService = await service();
    return this.get(importedService);
  }
}

// todo move lazy agora to this file link: https://terrific-force.monday.com/boards/5325693173/views/123582205/pulses/5635519742
