import 'reflect-metadata';
import {Observable, first, firstValueFrom, from, isObservable, map, race, shareReplay} from 'rxjs';
import {AppInjector} from '../app.module';
import {LogLevel} from './logger-level.enum';
import {LogService} from './logger.service';

let logService: LogService;
let initialized = false;
const TIME_UNTIL_TIMEOUT = 15000;

let id = 0;
/**
 * Function to auto log messages and events. This function is a decorator factory.
 * @param {string} name - The name of the log, default is 'AutoLog'.
 * @returns {function} - A decorator function.
 */
export function autoLog(name: string = 'AutoLog') {
  const firstLoggedOfDecorator = performance.now();
  // This decorator logs the invocation and the return of a method, or access to a property.
  return function <T extends Object, K extends keyof T>(
    target: T | any,
    propertyKey: K | any, // the any here is to allow logging of private methods.
    descriptor: PropertyDescriptor
  ) {
    // Try to get the logging service from the injector, or fallback to console logging.

    !initialized && initTheLogService();
    const targetName = target.constructor.name;
    const propertyName: string | number =
      typeof propertyKey === 'symbol' ? propertyKey.toString() : propertyKey;

    const originalMethod = descriptor.value;

    // Override the method descriptor to log execution and return value.
    descriptor.value = function createLoggedMethod(...args: any[]) {
      const executionId = ++id;
      const start = performance.now();
      logService.logMessage(LogLevel.DEBUG, `${logContext()} ~ args:`, {
        ...args,
        ...logContextParams(),
      });

      let result;
      try {
        result = originalMethod.apply(this, args);

        // If the return is a promise, wait for it to complete to log the result.
        if (isPromise(result)) {
          result = firstValueFrom(logObservable()(from(result)));
        }

        // If the return is an observable, we subscribe and log each emission.
        if (result && isObservable(result)) {
          result = logObservable()(result);
        }
      } catch (error) {
        logService.logMessage(LogLevel.ERROR, `${logContext()} ~ error ~ Error: `, {
          error,
          ...logContextParams(),
        });
        throw error;
      }

      // Log the returned result.
      !isObservable(result) &&
        logService.logMessage(LogLevel.INFO, `${logContext()} ~ returns ~ Result:`, {
          result,
          ...logContextParams(),
        });

      return result;
      /**
       * Function to generate a log context.
       * @returns {string} - The log context.
       */
      function logContext() {
        return `${targetName}.${propertyName} ~ ${name}`;
      }
      function logContextParams() {
        return {
          executionId,
          deltaTime: `${Math.ceil(performance.now() - start)}ms`,
          firstLogged: ` ${Math.ceil(performance.now() - firstLoggedOfDecorator)}ms`,
        };
      }

      /**
       * Function to log observable.
       * @returns {function} - Function that takes an Observable and returns an Observable.
       */
      function logObservable<T>() {
        return (source: Observable<T>): Observable<T> =>
          new Observable((observer) => {
            const timeout = Symbol('timeout');
            const sharedSource = source.pipe(shareReplay({refCount: true, bufferSize: 1}));
            race([
              sharedSource,
              new Observable((observer) => {
                setTimeout(() => {
                  observer.next(timeout);
                  observer.complete();
                }, TIME_UNTIL_TIMEOUT);
              }),
            ])
              .pipe(first())
              .subscribe({
                next(value) {
                  if (value === timeout) {
                    logService.logMessage(
                      LogLevel.ERROR,
                      `${logContext()} ~ Observable timed out`,
                      {
                        ...logContextParams(),
                      }
                    );
                  }
                },
              });

            const subscription = sharedSource
              .pipe(
                map((value, i) => {
                  i === 0 &&
                    logService.logMessage(
                      LogLevel.INFO,
                      `${logContext()} ~ first emission ~ Emitted value: `,
                      {value, ...logContextParams()}
                    );
                  return value;
                })
              )
              .subscribe({
                next(value) {
                  observer.next(value as T);
                  logService.logMessage(LogLevel.DEBUG, `${logContext()} ~ Emitted value: `, {
                    value,
                    ...logContextParams(),
                  });
                },
                error(err) {
                  observer.error(err);
                  logService.logMessage(LogLevel.ERROR, `${logContext()} ~ Error: `, {
                    err,
                    ...logContextParams(),
                  });
                },
                complete() {
                  observer.complete();
                  logService.logMessage(LogLevel.INFO, `${logContext()} ~ Observable completed`, {
                    ...logContextParams(),
                  });
                },
              });

            // Unsubscribe from source when observer is done.
            return () => subscription.unsubscribe();
          });
      }
    };

    return descriptor as any;
  };
}

function initTheLogService() {
  try {
    logService = AppInjector.get(LogService);
    initialized = true;
  } catch (error) {
    logService = {
      logMessage: (level: LogLevel, message: string, metadata: any = {}) => {
        console[level](metadata);
      },
    };
  }
}

/**
 * Function to determine if a value is a Promise.
 * @param {any} value - The value to check.
 * @returns {boolean} - Returns true if the value is a Promise, otherwise false.
 */
function isPromise(value: any): value is Promise<any> {
  return typeof value?.then === 'function';
}
